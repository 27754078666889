import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RouteCombiner from "./Routes/RouteCombiner";
import Routes from "./Routes/Routes";
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoute from "./Routes/PublicRoute";
import { ButtonProvider } from "./context/ButtonContext";
import {  getUserAuth } from '../src/utils/LocalStorageUtils'

function App() {
  useEffect(() => {
    const handleTabClose = () => {
      localStorage.removeItem(getUserAuth());
    };
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
  return (
    <>
      <ButtonProvider> 
        <Router>
          <RouteCombiner
            routes={Routes}
            PrivateRoute={PrivateRoute}
            PublicRoute={PublicRoute}
          />
        </Router>
      </ButtonProvider>
    </>
  );
}

export default App;
