import React from "react";
import { Switch } from "react-router-dom";

const Routes = ({ PrivateRoute, PublicRoute, routes }) => {
  const RoutesMap = routes.map(
    ({
      Private,
      exact = true,
      Layout = ({ children }) => <>{children}</>,
      modules,
      Component,
      path,
    }) => {
      const ComponentWithLayout = () => {
        return (
          <Layout>
            <Component />
          </Layout>
        );
      };
      return Private
        ? [
            <PrivateRoute
              key={path}
              exact={exact}
              component={ComponentWithLayout}
              path={path}
            />,
            modules &&
              modules.map((childrenProps) => (
                <PrivateRoute
                  key={childrenProps.path}
                  exact={childrenProps.exact}
                  component={() => (
                    <Layout>
                      <childrenProps.Component />
                    </Layout>
                  )}
                  path={path + childrenProps.path}
                />
              )),
          ]
        : [
            <PublicRoute
              key={path}
              exact={exact}
              component={ComponentWithLayout}
              path={path}
            />,
            modules &&
              modules.map((childrenProps) => (
                <PublicRoute
                  key={childrenProps.path}
                  exact={childrenProps.exact}
                  component={() => (
                    <Layout>
                      <childrenProps.Component />
                    </Layout>
                  )}
                  path={path + childrenProps.path}
                />
              )),
          ];
    }
  );
  return <Switch> {RoutesMap}</Switch>;
};
export default Routes;
