import React, { useEffect, useState } from "react";
import { createEnach, getUserDetails, getUserEnachDetails } from "../apis/apis";
import CustomSnackbar from "../components/Toast/CustomSnackbar";
import { CreateDigio } from "../Digio/CreateDigio";
import { useHistory } from "react-router-dom";
import CardLayout from "../components/layout/CardLayout";
import NumericStepper from "../components/NumericStepper";

function NachEMandates() {
    const history = useHistory();

    const [noOfInstallments, setNoOfInstallments] = useState(0);
    const [investmentAmount, setInvestmentAmount] = useState(0);

    const [sipStartDate, setsipStartDate] = useState('');

    const [responseObject, setResponseObject] = useState({});

    const accountTypeOptions = [
        {"title": "Savings", "value": "savings"},
        {"title": "Current", "value": "current"},
        {"title": "CC", "value": "cc"},
        {"title": "Other", "value": "other"},
    ];
    const [accountType, setAccountType] = useState(accountTypeOptions[0].value);

    const [errorData, setErrorData] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const fetchUserEnachDetails = () => {
        getUserEnachDetails().then((data) => {
            if(data.status){
                history.replace(`/user/payment?amount=${investmentAmount}&tranType=${process.env.REACT_APP_TYPE_SIP_PG}`);
            } else {
                setErrorData(data.description);
                setOpenSnackbar(true);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const startDigioProcess = (res) => {
        CreateDigio(res.data.id, localStorage.getItem("email"), res.data.access_token.id)
        .then((data) => {
            setErrorData(data.message);
            setOpenSnackbar(true);
            // history.push(`/user/payment?amount=${investmentAmount}&tranType=${process.env.REACT_APP_TYPE_SIP_PG}`);
            fetchUserEnachDetails();
        }).catch((err) => {
            console.log(err);
        });
        // var options = {
            // "is_redirection_approach": false,
            // "is_iframe": true,
            // "environment": "sandbox",
        //     // "redirect_url" : "https://www.google.com",
        //     // "method":"biometric",
        //     // "dg_preferred_auth_type": "net_banking",
        // };
        // var digio = new window.Digio(options);
        // digio.init();
        // digio.submit(res.data.id, localStorage.getItem("email"), res.data.access_token.id);
    }

    const handleConfirmBtn = () => {
        createEnach({
            "accountType": accountType, "startDate": sipStartDate
        }).then((data) => {
            setResponseObject(data);
            if(data.status){
                startDigioProcess(data);
            } else {
                setErrorData(data.description);
                setOpenSnackbar(true);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const fetchuserData = async () => {
        const userDetails = await getUserDetails();

        if (userDetails.status) {
            const { investorSip, firstPayment } = userDetails.data;

            if(investorSip?.enachStatus !== process.env.REACT_APP_ENACH_STATUS_INPROGRESS){
                setNoOfInstallments(investorSip.totalInstallments);
                setInvestmentAmount(investorSip.installmentAmount);
            } else {
                history.push("/user/profile");
                // if(firstPayment){
                //     history.push("/user/home/");
                // } else {
                //     history.push(`/user/payment?amount=${investorSip.installmentAmount}&tranType=${process.env.REACT_APP_TYPE_SIP_PG}`);
                // }
            }
        }
    };

    useEffect(() => {
        fetchuserData();
    }, []);

  return (
    <CardLayout>
            <div className="container mt-5">
                <NumericStepper />
                <h3 className="title-md text-center mb-4">NACH E-Mandates</h3>

                <div className="card-raised mx-auto w-75 p-5 mb-5">
                    <h3 className="card-title-md text-center">E-Nach mandate for SIP</h3>
                </div>

                <h5 className="title-sm text-center mb-4">You have chosen to invest<br />INR {investmentAmount}/- monthly for duration of {noOfInstallments} months.</h5>

                <p className="card-text text-center mb-5">Please confirm to initiate auto debit from the bank.</p>
            </div>

            <p className="text-center text-muted mb-3">Select Date</p>
            <div className="d-flex justify-content-center mb-5">
                <input type="date" className="form-control-lg w-25" value={sipStartDate} onChange={(e)=>setsipStartDate(e.target.value)} />
            </div>

            <p className="text-center text-muted mb-3">Choose account type</p>
            <div className="d-flex justify-content-center mb-5">
                {
                    accountTypeOptions.map((data,key) => {
                        return (
                            <div className="form-check mx-3">
                                <input
                                    className="form-check-input me-2"
                                    type="radio" id={"at-radio"+key}
                                    value={data.value}
                                    onChange={(e)=>setAccountType(e.target.value)}
                                    checked={accountType === data.value}
                                />
                                <label
                                    className="form-check-label fw-bold"
                                    for={"at-radio"+key}>
                                        {data.title}
                                </label>
                            </div>
                        );
                    })
                }
            </div>

            <div className="d-flex justify-content-center">
                <button className="color-btn mb-5" onClick={handleConfirmBtn}>
                    CONFIRM
                </button>
            </div>

        <CustomSnackbar
          open={openSnackbar}
          severity="error"
          duration={2000}
          text={errorData}
          handleClose={handleSnackbarClose}
        />
        </CardLayout>
  );
}

export default NachEMandates;