import React, { useEffect } from "react";
import "../css/LoadingPage.css";
import { useHistory } from "react-router-dom";
import { updateEsignStatus } from "../apis/apis";

function LoadingPage() {
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = { status: 1 };
        const response = await updateEsignStatus(data);
        const { status } = response;

        if (status) {
          //   console.log(description);
          history.push("/user/mca-status");
        } else {
          history.push("/error");
          //   console.log(description);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (localStorage.getItem("MCA") === "true") {
      fetchData();
    } else {
      history.push("/user/agreement");
    }
  }, [history]);

  return (
    <div className="loading-page">
      <div className="loading-spinner"></div>
      <p className="loading-text">Loading...</p>
    </div>
  );
}

export default LoadingPage;
