import React from 'react'
import { toCompactPricePrefix } from '../../utils/StringUtil';

export default function CardContainer(props) {
    const { cardData } = props;

    return (
        <div className="row g-4 mb-5">
            <div className="col-6 col-md-4">
                <div className='card p-4 h-100 w-100'>
                    <h4 className="fs-med-medium">Portfolio Value</h4>
                    <h1>{cardData.portfolioValue?toCompactPricePrefix(cardData.portfolioValue):0}</h1>
                    {/* <p className="fs-normal-regular text-muted p-0 m-0">0 unrealized - 0 realized</p> */}
                </div>
            </div>
            <div className="col-6 col-md-4">
                <div className='card p-4 h-100 w-100'>
                    <h4 className="fs-med-medium">Total Invested</h4>
                    <h1>{cardData.totalInvestedAmount?toCompactPricePrefix(cardData.totalInvestedAmount):0}</h1>
                    <p className="fs-normal-regular text-muted p-0 m-0">{cardData.noOfInvestments + " Investments " + cardData.noOfOpportunity + " Startups"}</p>
                </div>
            </div>
            <div className="col-12 col-md-4">
                <div className='card p-4 h-100 w-100'>
                    <h4 className="fs-med-medium">Multiple</h4>
                    {cardData.multiple==="NaN"?<h1>0</h1>:<h1>{cardData.multiple}x</h1>}
                    <p className="fs-normal-regular text-muted p-0 m-0">{'---'}</p>
                </div>
            </div>
        </div>
    )
}
