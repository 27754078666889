import React from 'react';
import Skeleton from '@mui/material/Skeleton';

function ShimmerOpportunityCard() {

  return (
    <div className="border pointer rounded p-4 h-100 d-flex align-items-center justify-content-between">
      <div>
        <Skeleton variant="rectangular" width="100px" height="50px" className="mb-3 rounded" />
        <Skeleton variant="text" className="mb-3 rounded" />
      </div>

      <div className="d-flex justify-content-between mt-auto" >
        <div className='w-100 pe-4'>
          <Skeleton variant="rectangular" height='25px' width='40px' className="mb-2 rounded" />
          <Skeleton variant="text" className="mb-3 rounded" />
        </div>
        <div className='w-100'>
          <Skeleton variant="rectangular" height='25px' width='40px' className="mb-2 rounded" />
          <Skeleton variant="text" className="mb-3 rounded" />
        </div>
      </div>
    </div>
  )
  return (
    <div style={{ 'background-color': '#F4F4F8', 'border-radius': '16px', 'padding': '20px 30px' }}>
      <div className="d-flex align-items-center flex-column">


        <div className="w-100 mb-4">
          <Skeleton height={20} width="100%" style={{ marginBottom: 3 }} />
          <Skeleton height={20} width="100%" />
        </div>

        <div className="row w-100 mb-4">
          <div className="col">
            <Skeleton variant="rectangular" width="100%" height="80px" style={{ 'border-radius': '7px' }} />
          </div>
          <div className="col">
            <Skeleton variant="rectangular" width="100%" height="80px" style={{ 'border-radius': '7px' }} />
          </div>
        </div>

        <Skeleton variant="rectangular" width="30%" height="25px" className="mb-3" style={{ 'border-radius': '5px' }} />

        <Skeleton variant="rectangular" width="15%" height="20px" className="mb-4" style={{ 'border-radius': '5px' }} />

        <Skeleton variant="rectangular" width="50%" height="40px" style={{ 'border-radius': '7px 0' }} />
      </div>
    </div>
  );
}

export default ShimmerOpportunityCard;