import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom';

import { getFatcaDrawDownDetails } from "../../apis/apis";
import { setDashboardTab,getUserAuth, getItem, FLAG_REDIRECT_FROM_DOCUMENTS } from "../../../utils/LocalStorageUtils";
import AuthLayout from "../../components/layout/AuthLayout";
import CustomSnackbar from "../../components/Toast/CustomSnackbar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, LinearProgress } from '@mui/material';


function FatcaSuccessfull(props) {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mcaEvent = searchParams.get('event');
    const envelopId = searchParams.get('envelopId');

    const [errorData, setErrorData] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const [FatcaStatus, setFatcaStatus] = useState(-1);


    const fetchFatcaData = async () => {

        try {
            const FatcaDetails = await getFatcaDrawDownDetails({
                "envelopId": envelopId
            });
            if (FatcaDetails.status) {
                const { status } = FatcaDetails.data;

                if (status === 1) {

                    setFatcaStatus(status);

                    // else {
                    //     history.replace("/success-lp", {"alertTitle": 'Fatca Successfully Signed'});
                    // }
                } else {
                    // setErrorData('MCA not signed');
                    setErrorData("You need to complete the Fatca signing to proceed further with the investments");
                    setOpenSnackbar(true);
                    setTimeout(() => {
                        history.replace("/user/Fatca");
                    }, 2000);
                }
            } else {
                setErrorData(FatcaDetails.description);
                setOpenSnackbar(true);
                setTimeout(() => {
                    history.replace("/user/Fatca");
                }, 2000);

            }
        } catch (error) {
            setErrorData(error);
            openSnackbar(true);
        }

    };

    const handleContinue = async () => {
        setDashboardTab(1);
        history.push("/user/home")
    };

    useEffect(() => {
        setTimeout(() => {
            fetchFatcaData();
        }, 2000);
    }, []);

    return (
        <AuthLayout title={FatcaStatus === -1 ? '' : 'Fatca Successfully Signed'}>
            {
                FatcaStatus === -1
                    ? <div className="d-flex justify-content-center">
                        <div className="loading-spinner"></div>
                    </div>
                    : <>
                        {/* <p className='fs-normal-medium text-primary mb-4'>Trustee and Investment  Manager signatures pending . You will be notified once the document is ready for download.</p> */}
                        <div className='d-flex mt-3'>

                            {getUserAuth() && !getItem(FLAG_REDIRECT_FROM_DOCUMENTS) ?
                                <>
                                    <div className="col-4">
                                        <div className='d-flex mt-3'>
                                            <button type="button" onClick={handleContinue} className="btn btn-primary px-3 py-2">Continue</button>

                                        </div>
                                    </div>
                                </>
                                : ""}
                            {getUserAuth() && getItem(FLAG_REDIRECT_FROM_DOCUMENTS) ?
                                <div className="col-4">
                                    <div className='d-flex mt-3'>
                                        <button type="button"
                                            onClick={() => history.push("/user/documents/")}
                                            className="btn btn-primary px-3 py-2">Finish</button>
                                    </div>
                                </div> : null
                            }
                        </div>
                    </>
            }
            <CustomSnackbar
                open={openSnackbar}
                severity="error"
                duration={2000}
                text={errorData}
                handleClose={handleSnackbarClose}
            />
        </AuthLayout>
    );
}

export default FatcaSuccessfull;
