import React, { useState, useEffect, useRef, useMemo } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import VisibilitySensor from 'react-visibility-sensor';
import { toCompactPricePrefix } from "../../utils/StringUtil";

const CustomPieChart = ({ investmentByRounds }) => {
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [collisions, setCollisions] = useState([]);
  const renderedValues = useRef(new Map());
  const renderedPositions = useRef([]);
  const smallSegments = useRef([]);
  const labelRefs = useRef([]);

  const animationFrameId = useRef(null);
  const adjustmentCount = useRef(0);
  const MAX_ADJUSTMENTS = 50;


  const MINIMUM_PERCENTAGE = 0.5;
  const MINIMUM_PERCENTAGE_LABLE = 10;
  const MINIMUM_SEGMENT_SIZE = 1;
  const VISIBILITY_THRESHOLD = 0.5;
  const VISIBILITY_CHECK_DELAY = 10;

  const COLORS = [
    "#FFD700", "#FFA07A", "#FFC0CB", "#98FB98", "#B0E0E6",
    "#F5DEB3", "#FFA500", "#DDA0DD", "#00CED1", "#E6E6FA",
  ];

  const startAngleAdjustment = () => {
    if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
    }

    const adjustAngles = () => {
      if (!isVisible || adjustmentCount.current >= MAX_ADJUSTMENTS) {
        cancelAnimationFrame(animationFrameId.current);
        return;
      }

      checkLabelCollisions();

      if (collisions.length > 0) {
        adjustmentCount.current++;
        animationFrameId.current = requestAnimationFrame(adjustAngles);
      } else {
        adjustmentCount.current = 0;
        cancelAnimationFrame(animationFrameId.current);
      }
    };

    adjustmentCount.current = 0;
    animationFrameId.current = requestAnimationFrame(adjustAngles);
  };

  useEffect(() => {
    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible && data.length > 0) {
      startAngleAdjustment();
    }
  }, [isVisible, data]);




  const handleVisibilityChange = (isComponentVisible) => {
    setIsVisible(isComponentVisible);
    if (isComponentVisible) {
      checkLabelCollisions();
      startAngleAdjustment();
    }
  };

  const checkLabelCollisions = () => {
    if (!isVisible) return;

    const newCollisions = [];
    labelRefs.current.forEach((ref, i) => {
      if (!ref) return;
      const rect1 = ref.getBoundingClientRect();

      labelRefs.current.forEach((otherRef, j) => {
        if (i >= j || !otherRef) return;
        const rect2 = otherRef.getBoundingClientRect();

        const isColliding = !(
          rect1.right < rect2.left ||
          rect1.left > rect2.right ||
          rect1.bottom < rect2.top ||
          rect1.top > rect2.bottom
        );

        if (isColliding) {
          newCollisions.push([i, j]);
        }
      });
    });

    setCollisions(newCollisions);
  };

  useEffect(() => {
    if (!isVisible) return;

    const timer = setTimeout(() => {
      checkLabelCollisions();
    }, VISIBILITY_CHECK_DELAY);

    return () => clearTimeout(timer);
  }, [data, isVisible]);

  useEffect(() => {
    const total = investmentByRounds.reduce(
      (sum, inv) => sum + inv.totCommitment,
      0
    );

    const smallSegmentIndices = investmentByRounds
      .map((inv, index) => ({
        percentage: (inv.totCommitment / total) * 100,
        index,
      }))
      .filter((item) => item.percentage <= MINIMUM_PERCENTAGE)
      .map((item) => item.index);

    smallSegments.current = smallSegmentIndices;

    const segmentsNeedingAdjustment = investmentByRounds.filter(
      (inv) => (inv.totCommitment / total) * 100 <= MINIMUM_PERCENTAGE
    );

    const totalAdditionalSize =
      segmentsNeedingAdjustment.length * ((total * MINIMUM_SEGMENT_SIZE) / 100);

    const adjustmentFactor = (total - totalAdditionalSize) / total;

    const sortedData = investmentByRounds.map((inv, index) => {
      const percentage = (inv.totCommitment / total) * 100;
      let adjustedValue;

      adjustedValue = inv.totCommitment + (total * MINIMUM_SEGMENT_SIZE) / 100;



      return {
        name: inv.invRound,
        value: adjustedValue,
        originalValue: inv.totCommitment,
        angle: 0,
        isSmall: percentage <= MINIMUM_PERCENTAGE,
      };
    });

    setData(sortedData);
    renderedValues.current.clear();
    renderedPositions.current = [];
  }, [investmentByRounds]);

  const CustomTooltip = useMemo(() => {
    return ({ active, payload }) => {
      if (!active || !payload?.[0]) return null;

      const item = payload[0].payload;
      return (
        <div className="bg-white p-3 rounded text-muted shadow" style={{ width: "auto" }}>
          <div className="row justify-content-between">
            <div className="d-flex align-items-center w-auto">
              <div style={{ background: item.fill }} className="indicator d-block" />
              <p className="fs-6 w-auto mb-0 ps-2">{item.name}</p>
            </div>
            <p className="fs-6 mb-0 w-auto">
              {toCompactPricePrefix(item.originalValue)}
            </p>
          </div>
        </div>
      );
    };
  }, []);


  const renderCustomizedLabel = ({
    cx,
    cy,
    outerRadius,
    index,
    value,
    startAngle,
    endAngle,
  }) => {
    const RADIAN = Math.PI / 180;
    const LABEL_OFFSET = 3;
    const BASE_HORIZONTAL_LINE_LENGTH = 20;
    const HORIZONTAL_GAP = 50;
    const LINE_BUFFER_RADIUS = 5;
    const VERTICAL_GAP = 20;
    const TEXT_PADDING = 8;
    const CONTAINER_PADDING = 20;

    // Container boundaries
    const containerWidth = 600;
    const containerHeight = 320;
    const boundaryLeft = CONTAINER_PADDING;
    const boundaryRight = containerWidth - CONTAINER_PADDING;
    const boundaryTop = CONTAINER_PADDING;
    const boundaryBottom = containerHeight - CONTAINER_PADDING;

    const originalTotal = data.reduce(
      (sum, item) => sum + item.originalValue,
      0
    );
    const originalPercent = (data[index].originalValue / originalTotal) * 100;

    // Calculate base positions
    const baseAngle = (startAngle + endAngle) / 2;
    const isRightSide = Math.cos(-baseAngle * RADIAN) >= 0;

    // Boundary constraint function
    const constrainPoint = (x, y) => ({
      x: Math.max(boundaryLeft, Math.min(boundaryRight, x)),
      y: Math.max(boundaryTop, Math.min(boundaryBottom, y))
    });

    // Initial position calculation (fallback positions)
    const initialStartX = cx + outerRadius * Math.cos(-baseAngle * RADIAN);
    const initialStartY = cy + outerRadius * Math.sin(-baseAngle * RADIAN);
    const initialLabelDistance = outerRadius + LABEL_OFFSET;
    const initialVerticalEndX = cx + initialLabelDistance * Math.cos(-baseAngle * RADIAN);
    const initialVerticalEndY = cy + initialLabelDistance * Math.sin(-baseAngle * RADIAN);
    const initialHorizontalEndX = initialVerticalEndX + (isRightSide ? BASE_HORIZONTAL_LINE_LENGTH : -BASE_HORIZONTAL_LINE_LENGTH);
    const initialHorizontalEndY = initialVerticalEndY;

    // Constrain initial positions
    const constrainedInitial = {
      startX: constrainPoint(initialStartX, initialStartY).x,
      startY: constrainPoint(initialStartX, initialStartY).y,
      verticalEndX: constrainPoint(initialVerticalEndX, initialVerticalEndY).x,
      verticalEndY: constrainPoint(initialVerticalEndX, initialVerticalEndY).y,
      horizontalEndX: constrainPoint(initialHorizontalEndX, initialHorizontalEndY).x,
      horizontalEndY: constrainPoint(initialHorizontalEndX, initialHorizontalEndY).y
    };

    let stepIndex = 0;
    let horizontalLineLength = BASE_HORIZONTAL_LINE_LENGTH;
    let finalPositions = constrainedInitial; // Start with constrained initial positions

    // Line collision detection function
    const checkLineCollision = (line1Start, line1End, line2Start, line2End) => {
      const distance = (point, lineStart, lineEnd) => {
        const A = point.x - lineStart.x;
        const B = point.y - lineStart.y;
        const C = lineEnd.x - lineStart.x;
        const D = lineEnd.y - lineStart.y;

        const dot = A * C + B * D;
        const lenSq = C * C + D * D;
        let param = -1;

        if (lenSq !== 0) param = dot / lenSq;

        let xx, yy;
        if (param < 0) {
          xx = lineStart.x;
          yy = lineStart.y;
        } else if (param > 1) {
          xx = lineEnd.x;
          yy = lineEnd.y;
        } else {
          xx = lineStart.x + param * C;
          yy = lineStart.y + param * D;
        }

        const dx = point.x - xx;
        const dy = point.y - yy;
        return Math.sqrt(dx * dx + dy * dy);
      };

      const points = 5;
      for (let i = 0; i <= points; i++) {
        const t = i / points;
        const pointX = line1Start.x + t * (line1End.x - line1Start.x);
        const pointY = line1Start.y + t * (line1End.y - line1Start.y);
        const point = { x: pointX, y: pointY };

        if (distance(point, line2Start, line2End) < LINE_BUFFER_RADIUS * 2) {
          return true;
        }
      }
      return false;
    };

    // Try to find better positions avoiding collisions
    if (renderedPositions.current.length > 0) {
      do {
        horizontalLineLength = BASE_HORIZONTAL_LINE_LENGTH + (stepIndex * HORIZONTAL_GAP);
        const labelDistance = outerRadius + LABEL_OFFSET + (stepIndex * VERTICAL_GAP);

        // Calculate candidate positions
        const startX = cx + outerRadius * Math.cos(-baseAngle * RADIAN);
        const startY = cy + outerRadius * Math.sin(-baseAngle * RADIAN);
        const verticalEndX = cx + labelDistance * Math.cos(-baseAngle * RADIAN);
        const verticalEndY = cy + labelDistance * Math.sin(-baseAngle * RADIAN);
        const horizontalEndX = verticalEndX + (isRightSide ? horizontalLineLength : -horizontalLineLength);
        const horizontalEndY = verticalEndY;

        // Constrain points
        const constrainedStart = constrainPoint(startX, startY);
        const constrainedVertical = constrainPoint(verticalEndX, verticalEndY);
        const constrainedHorizontal = constrainPoint(horizontalEndX, horizontalEndY);

        const candidatePositions = {
          startX: constrainedStart.x,
          startY: constrainedStart.y,
          verticalEndX: constrainedVertical.x,
          verticalEndY: constrainedVertical.y,
          horizontalEndX: constrainedHorizontal.x,
          horizontalEndY: constrainedHorizontal.y
        };

        // Check for collisions
        let hasCollision = false;
        for (let i = 0; i < index; i++) {
          const prevPos = renderedPositions.current[i];
          if (!prevPos) continue;

          if (checkLineCollision(
            { x: candidatePositions.verticalEndX, y: candidatePositions.verticalEndY },
            { x: candidatePositions.horizontalEndX, y: candidatePositions.horizontalEndY },
            { x: prevPos.verticalEndX, y: prevPos.verticalEndY },
            { x: prevPos.horizontalEndX, y: prevPos.horizontalEndY }
          )) {
            hasCollision = true;
            break;
          }
        }

        if (!hasCollision) {
          finalPositions = candidatePositions;
          break;
        }

        stepIndex++;
      } while (stepIndex < 10);
    }

    // Store positions for future collision checks
    renderedPositions.current[index] = {
      ...finalPositions,
      isColliding: stepIndex > 0
    };

    const segmentColor = COLORS[index % COLORS.length];

    // Calculate text position with boundary awareness
    const textX = finalPositions.horizontalEndX +
      (isRightSide ? Math.min(TEXT_PADDING, boundaryRight - finalPositions.horizontalEndX) :
        Math.max(-TEXT_PADDING, boundaryLeft - finalPositions.horizontalEndX));

    return (
      <g style={{ pointerEvents: "none" }}>
        <line
          x1={finalPositions.startX}
          y1={finalPositions.startY}
          x2={finalPositions.verticalEndX}
          y2={finalPositions.verticalEndY}
          stroke={segmentColor}
          strokeWidth={1.5}
        />
        <line
          x1={finalPositions.verticalEndX}
          y1={finalPositions.verticalEndY}
          x2={finalPositions.horizontalEndX}
          y2={finalPositions.horizontalEndY}
          stroke={segmentColor}
          strokeWidth={1.5}
        />
        <text
          ref={el => labelRefs.current[index] = el}
          x={textX}
          y={finalPositions.horizontalEndY}
          fill="#555555"
          textAnchor={isRightSide ? "start" : "end"}
          dominantBaseline="central"
          style={{ fontSize: "13px" }}
        >
          {`${originalPercent.toFixed(0)}%`}
        </text>
      </g>
    );
  };
  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility={true}
      offset={{ top: 50, bottom: 50 }}
      delayedCall={true}
      minTopValue={VISIBILITY_THRESHOLD}
    >
      <div className="row">
        <div className="col-5 ps-0 mt-3">
          {investmentByRounds.map((inv, index) => (
            <div className="d-flex justify-content-between mt-2 ms-1" key={`item-${index}`}>
              <div className="d-flex align-items-center">
                <div
                  className="indicator"
                  style={{ background: COLORS[index % COLORS.length] }}
                />
                <p className="mb-0 ms-2 pe-3">{inv.invRound}</p>
              </div>
              <p className="mb-0">{toCompactPricePrefix(inv.totCommitment)}</p>
            </div>
          ))}
        </div>
        <div className="col-7 px-0">
          <div style={{
            width: 370,
            height: 320,
            maxWidth: "600px",
            margin: "0 auto",
          }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  cx="50%"
                  cy="50%"
                  data={data}
                  innerRadius={40}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  label={renderCustomizedLabel}
                  labelLine={null}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={CustomTooltip} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>


      </div>
    </VisibilitySensor>
  );
};

export default CustomPieChart;
















