import React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AuthLayout from '../components/layout/AuthLayout';
import { useEffect } from 'react';
import { showInterest } from '../apis/apis';
import { useSnackbar } from '../../context/SnackbarContext';

const ShowInterest = () => {

    const SUCCESS = "0"
    const ERROR = "-1"

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState()

    const history = useHistory()

    const { showSnackbar } = useSnackbar()

    const { id } = useParams()

    const onExploreMore = () => {
        history.push('/')
    }

    useEffect(() => {
        showInterest(id).then(res => {
            if (res.status) {
                setIsLoading(false)
                const { statusCode, oppRid } = res.data;
                if(statusCode){
                    history.replace(`/user/invest-now/${oppRid}`, {...res.data, isEmailInvestment: 1, token: id});
                }
                setData(res.data)
            } else {
                showSnackbar(res.description)
            }
        }).catch(e => {
            showSnackbar(e)
        })
    }, [])

    return (
        isLoading ? <div className='preview-loader-wrapper'>
            <img className="logo" src={"/logo2.svg"} alt={"logo"} />
        </div> : (
            data && <AuthLayout title={data.oppTitle}>
                <div className="d-flex flex-column">
                    <p className={`${data.statusCode === ERROR ? 'text-danger' : ''} fs-5`}>
                        {data.message}
                    </p>
                    {/* <div className='mt-2'>
                        <button type="button" onClick={onExploreMore} className="btn btn-primary px-3 py-2 w-auto">Explore More</button>
                    </div> */}
                </div>
            </AuthLayout>
        )
    )
}

export default ShowInterest
