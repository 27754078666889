
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { getPartner_Id, setPartner_Id } from '../../../utils/LocalStorageUtils';
import { useSnackbar } from '../../../context/SnackbarContext';
import { fetchPartnerIdFromApi } from '../../../utils/FetchLogo';

function CardLayout({ children, showBackButton = true }) { 
  const history = useHistory();
  const [logo, setLogo] = useState(null);
  const [logoError, setLogoError] = useState(null);
  const { showSnackbar } = useSnackbar()
  const [partnerId, setPartnerId] = useState(getPartner_Id());


  const fetchLogo = (partnerId) => {
    return `${process.env.REACT_APP_BASE_URL}/logo/${partnerId}`; 
  };

  useEffect(() => {
    if (!partnerId) {
      fetchPartnerIdFromApi(
        setPartnerId,
        setPartner_Id,
        showSnackbar
      );
      setLogo(fetchLogo(partnerId));
    } else {
      setLogo(fetchLogo(partnerId));
      setLogoError(null); 
    }
  }, [partnerId]);

  return (
    <div className="art-board py-md-4 d-flex flex-column h-100">
      <div className="container card-flat flex-grow-1 p-4 p-md-4 overflow-auto">
        <div className="d-flex gap-3 align-items-center">
        
          {showBackButton && (
            <IconButton size="large" onClick={() => history.replace("/user/home")}>
              <ArrowBackIcon />
            </IconButton>
          )}
          
          <img
            src={logo}
            alt={"logo"}
            className="p-4"
            onClick={() => history.push("/user/home")}
          />
        </div>

        {children}
      </div>
    </div>
  );
}

export default CardLayout;





