import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { getInvestmentDetail } from '../apis/apis'
import NavBar from '../components/NavBar'
import { formatDate, toCompactPricePrefix } from '../../utils/StringUtil'
import CompanyUpdateModal from '../components/CompanyUpdateModal'
import Footer from '../components/Footer'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useHistory } from "react-router-dom";
import CoInvestor from '../components/CoInvestor'

const InvestmentDetail = () => {

    const { ioRid } = useParams()

    const [investDetail, setInvestmentDetail] = useState(null)
    const [open, setOpen] = useState(false);
    const [cuRid, setCuRid] = useState(null);

    let history = useHistory();


    useEffect(() => {
        formatDate()
        getInvestmentDetail(ioRid)
            .then(res => setInvestmentDetail(res.data))
            .catch(e => console.error(e))
    }, [])

    const handleOpen = (cuRid) => {
        setOpen(true);
        setCuRid(cuRid);
    }

    const handleClose = () => {
        setOpen(false);
        setCuRid(null);
    }

    const onBackClick = () => {
        history.goBack()
    }

    return (
        investDetail && <>
            <NavBar />
            <div className="container pb-5">
                <KeyboardBackspaceIcon onClick={onBackClick} className='text-dark mt-4 pointer' />
                <div className="border rounded p-4 mt-4 row mx-0">
                    <div className="col-5">
                        <div className='d-flex align-items-center'>
                            <img
                                src={
                                    investDetail.compLogo ?
                                        process.env.REACT_APP_BASE_URL + "/" + investDetail.compLogo :
                                        process.env.PUBLIC_URL + "/img/placeholder.png"
                                }
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = process.env.PUBLIC_URL + "/img/placeholder.png";
                                }} className="thumbnail-img" alt="" />
                            <div className='ps-3'>
                                <p className='mb-0 fs-3'>{investDetail.compTitle}</p>
                                <p className='mb-0 text-muted'>{investDetail.oppTitle}</p>
                                <p className="fs-5 mb-0  mt-4">
                                     Legal Name of Entity
                                 </p>
                                 <p className="fs-6 text-muted mb-0">
                                     {investDetail.legalNameOfEntity}
                                 </p>
                            </div>
                        </div>
                    </div>                
                    <div className="col-md border-start ps-4" >
                        <p className="fs-5 mb-1">
                            Domain
                        </p>
                        <p className="fs-6 text-muted mb-0">
                            {investDetail.domain}
                        </p>
                    </div>
                    <div className="col-md ps-4">
                        <p className="fs-5 mb-1">
                            Stage
                        </p>
                        <p className="fs-6 text-muted mb-0">
                            {investDetail.stageOfCompany}
                        </p>
                    </div>
                    <div className="col-md ps-4">
                        <p className="fs-5 mb-1">
                            Round
                        </p>
                        <p className="fs-6 text-muted mb-0">
                            {investDetail.invRound}
                        </p>
                    </div>
                </div>
                <div className="border rounded p-4 mt-4 row mx-0">
                    <div className="w-75 d-flex flex-column">
                        <div className='row'>
                            <div className="col">
                                <p className='mb-0 fs-5'>
                                    Invested
                                </p>
                                <p className='fs-3 mb-0'>
                                    {toCompactPricePrefix(investDetail.commitment)}
                                </p>
                                <p className='mb-0 fs-6 text-muted'>
                                    {investDetail.investmentDate && 'on'} {formatDate(investDetail.investmentDate)}
                                </p>
                            </div>
                            <div className="col">
                                <p className='mb-0 fs-5'>
                                    Valuation
                                </p>
                                <p className='fs-3 mb-2'>
                                    {toCompactPricePrefix(investDetail.currentValuation)}
                                </p>
                            </div>
                            <div className="col">
                                <p className='mb-0 fs-5'>
                                    Multiple
                                </p>
                                <p className='fs-3 mb-2'>
                                    {investDetail.multiple}{investDetail.multiple > 0 && 'x'}
                                </p>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col">
                                <p className='mb-0 fs-5'>
                                    Class
                                </p>
                                <p className='fs-3 mb-2'>
                                    {investDetail.className}
                                </p>
                            </div>
                            <div className="col">
                                <p className='mb-0 fs-5'>
                                    Hurdle
                                </p>
                                <p className='fs-3 mb-2'>
                                    {investDetail.hurdleRate}%
                                </p>
                            </div>
                            <div className="col">
                                <p className='mb-0 fs-5'>
                                    Carry
                                </p>
                                <p className='fs-3 mb-2'>
                                    {investDetail.carry}%
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="col">
                        {
                            investDetail.valuationUpdateList.length > 0 &&
                            <>
                                <p className="fs-5 mb-3">
                                    Valuation history
                                </p>
                                {
                                    investDetail.valuationUpdateList.map((vu, index) => (
                                        <div className="d-flex" key={index}>
                                            <div className="col">
                                                <p className='mb-1 text-muted fs-normal-medium'>{formatDate(vu.valuationDate)}</p>
                                            </div>
                                            <div className="col">
                                                <p className='mb-1 text-muted fs-normal-medium'> {toCompactPricePrefix(vu.amount)}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                        }
                    </div>
                </div>

                {
                    investDetail.coInvestorsList.length > 0 &&
                    <div className="border d-flex flex-column align-items-center rounded p-4 mt-4">
                        <p className='fs-4'>Co-Investors</p>
                        <CoInvestor coInvestorsList={investDetail.coInvestorsList} />
                    </div>
                }

                {
                    investDetail.companyUpdateList.length > 0 &&
                    <div className="border rounded p-4 mt-4 row mx-0">
                        <p className="fs-4">
                            Activities
                        </p>
                        <div className="px-2">
                            {
                                investDetail.companyUpdateList.map((comp, index) => (
                                    <div key={index} className="card pointer p-3 mb-2" onClick={() => handleOpen(comp.cuRid)}>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column col">
                                                <h5 className="fs-6 mb-1">{comp.title}</h5>
                                                <p className="fs-normal-medium text-muted p-0 m-0">{comp.authorName} on <span className='fst-italic'>{formatDate(comp.publishDate)}</span></p>
                                            </div>
                                            <div >
                                                <img
                                                    src={
                                                        comp.logoUrl ?
                                                            process.env.REACT_APP_BASE_URL + "/" + comp.logoUrl :
                                                            process.env.PUBLIC_URL + "/img/placeholder.png"
                                                    }
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = process.env.PUBLIC_URL + "/img/placeholder.png";
                                                    }}
                                                    alt="company-banner"
                                                    className='thumbnail-img'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </div >
            <Footer />

            <CompanyUpdateModal
                open={open}
                closeAction={handleClose}
                companyUpdateRid={cuRid}
            />
        </>
    )
}

export default InvestmentDetail
