import { getItem } from "./LocalStorageUtils"

export const toLocalePrice = (value) => {
    return Number(+value).toLocaleString('en-IN', { currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export const toLocalePriceNoDecimal = (value) => {
    return Number(+value).toLocaleString('en-IN', { currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
}

export const toCompactPricePrefix = (value) => {
    return `₹ ${toCompactPrice(value)}`
}

export const toCompactPrice = (value) => {
    return Number(+value)
        .toLocaleString(
            'en-IN', {
            //@ts-ignore
            notation: "compact",
            compactDisplay: "short",
            maximumFractionDigits: 2,
        }).replace('T', 'K');
}

export const formatDate = (inputDate) => {
    if(!inputDate) return ''
    const date = new Date(inputDate);
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const year = date.getFullYear();
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    return `${day} ${month}, ${year}`;
}

export const isForeigner=()=>{
    if((getItem("countryCode") !== "91" && getItem("countryCode") !== "+91") && (getItem("dialCode") !== "91" && getItem("dialCode") !== "+91")){
        return true;
    }
    return false;
}

export const formatDateTime = (dateTimeString, flagShowTime) => {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedYear = year;

    let formattedTime = '';
    if (flagShowTime) {
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
        formattedTime = ` ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
  
    return `${formattedDay}/${formattedMonth}/${formattedYear}${formattedTime}`;
}

export const isValidNsdldpId = (nsdlDpId) => {
    return /^IN[0-9]{6}$/.test(nsdlDpId);
}

export const isValidCdsldpId = (cdslDpId) => {
    return /^[0-9]{8}$/.test(cdslDpId);
}

export const isValidClientId = (clientId) =>{
    return /^[0-9]{8}$/.test(clientId);
}