import NavBar from "./NavBar";
import { useState ,useEffect} from "react";
import { useSnackbar } from "../../context/SnackbarContext";
import {
    apiGetSchemeEmailerConvenienceFee,
    apiSaveConsent,
    getWalletSchemeEmailer,
    apiSaveSchemeEmailerConsent,
    apiGetRegisteredCommitmentDetails,
    getUserDetails,

} from "../apis/apis";
import { BASE_URL } from "../../utils/constants/constants";
import { getUserAuth, setDashboardTab } from "../../utils/LocalStorageUtils";
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Footer from "./Footer";
import { Checkbox, FormControlLabel } from "@mui/material";
import FileView from "../../components/ui/FileView";


export default function DrawDownComponent({ isEmailInvestment, oppDetail, token, ioRid, path, Dialog, onCancel }) {

    let history = useHistory()
    const location = useLocation();
    const [consent, setConsent] = useState(false);
    const { showSnackbar } = useSnackbar()
    const [convenienceFee, setConvenienceFee] = useState(0);
    const [walletAmount, setWalletAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const [saving, setSaving] = useState(false);
    const [pathToShow, setPathToShow] = useState(null)
    const [userDetails,setUserDetails] = useState(null);
    const [commitmentDetail, setCommitmentDetail] = useState(null)

    const getFileName=()=>{
        return oppDetail?.invCode+"-"+oppDetail?.oppTitle+"-"+oppDetail?.className
    }

    const fetchLocation = async () => {
        if ("geolocation" in navigator) {
            try {
                const position = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });

                const { latitude, longitude } = position.coords;

                return { lat: latitude, lon: longitude };
            } catch (error) {
                console.error("Error getting geolocation: " + error.message);
            }
        } else {
            console.error("Geolocation is not available in your browser.");
        }
    }

    const onSubmitConsent = async () => {
        
        if (!consent) {
            showSnackbar("Please accept the consent")
            return
        }

        const latLang = await fetchLocation()

        if (!latLang) {
            showSnackbar("Please enable location permission")
            return
        }

        const body = {
            // diRid: drawdownData.diRid,
            lat: latLang.lat,
            lon: latLang.lon,
            consent: consent,
            ioRid: ioRid,
            token: token
        }
        setSaving(true)

        if (isEmailInvestment) {
            apiSaveSchemeEmailerConsent(body)
                .then(res => {
                    history.replace(`/user/commitment-payment?token=${token}&isEmailInvestment=${isEmailInvestment}&ioRid=${ioRid}&tranType=${process.env.REACT_APP_TYPE_COMMITMENT}`);
                })
                .catch(e => showSnackbar(e))
                .finally(() => {
                    setSaving(false)
                })
        } else
            if (getUserAuth()) {
                apiSaveConsent(body)
                    .then(res => {
                        history.replace(`/user/commitment-payment?walletBalance=${walletAmount}&ioRid=${ioRid}&tranType=${process.env.REACT_APP_TYPE_COMMITMENT}`);
                    })
                    .catch(e => showSnackbar(e))
                    .finally(() => {
                        setSaving(false)
                    })
            }
    }
    return (
        <>
            <div >
                {isEmailInvestment ? '' : <NavBar />}
                {(!isLoading && oppDetail) && <div className="container mt-4">
                    <p className="fs-title-lg-semibold fw-bold text-center mb-4">Drawdown - {oppDetail?.oppTitle}</p>
                    <div className="row mb-5">
                        <div className="col-12">
                            <>
                                {/* <iframe width="100%" className='drawdown-notice' src={`${BASE_URL}/${path}`} ></iframe> */}
                                <FileView url={`${BASE_URL}/${path}`} fileName={getFileName()}  showDownload={true} />


                                <div className="d-flex gap-5 justify-content-between align-items-center mt-3 mx-0">
                                    <FormControlLabel className='w-auto mx-0 pe-0' control={<Checkbox value={consent} onChange={e => setConsent(!consent)} />} label="I Consent to this Investment" labelPlacement='start' />

                                    <div className="d-flex align-items-center">
                                        <button
                                            onClick={() => {
                                                if (Dialog) {
                                                    onCancel()
                                                }
                                                history.replace("/user/home")
                                            }}
                                            className="btn w-auto px-4 me-3">
                                            Cancel
                                        </button>
                                        <button disabled={!consent} onClick={onSubmitConsent} className="btn btn-primary w-auto px-4">
                                            Proceed
                                        </button>
                                    </div>
                                </div>
                                <div className="row mx-0 justify-content-end mt-4">
                                </div>
                            </>
                        </div>
                    </div>
                </div>
                }
                <Footer />
            </div>
        </>
    );
}
