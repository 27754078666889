import React, { useState, useEffect } from 'react';
import { getUserDetails } from '../apis/apis';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

function NumericStepper() {
    const [stepCount, setStepCount] = useState(3);

    const [mcaStatus, setMcaStatus] = useState(0);
    const [ckycStatus, setCkycStatus] = useState(0);
    const [eNachStatus, setENachStatus] = useState(0);

    const profileCompleteSteps = [
        // {
        //     "title": "Create Acoount",
        //     "status": 1,
        // },
        {
            "title": "CKYC Verification",
            "status": ckycStatus,
        },
        {
            "title": "Master Contribution Agreement",
            "status": mcaStatus,
        },
        {
            "title": "SIP eNach",
            "status": eNachStatus,
        },
    ];

    const stepperIndexStyle = {
        'display': 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'border-radius': '50px',
        'font-weight': '600',
        'height': '50px', 'min-width': '50px'
    }

    const fetchUserData = async () => {
        const userDetails = await getUserDetails();  
        if (userDetails.status) {
            const { mcaStatus, ckycStatus, investorSip } = userDetails.data;
            if(investorSip){
                if(investorSip?.enachStatus === process.env.REACT_APP_ENACH_STATUS_INPROGRESS){
                    setENachStatus(1);
                }
            }
            setMcaStatus(mcaStatus);
            setCkycStatus(ckycStatus);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    return (
        <div className='col-12 col-md-6 d-flex justify-content-center align-items-center flex-row mx-auto p-5'>
            {
                profileCompleteSteps.map((data, key)=> {
                    return(
                        <>
                        <div className={data.status === 1 ? 'bg-success text-white' : 'bg-light text-black'} style={stepperIndexStyle}>
                            { data.status === 1 ? <CheckRoundedIcon /> : key+1 }
                        </div>
                        { profileCompleteSteps.length !== key+1 ? <div className={'mx-auto ' + (data.status === 1 ? 'bg-success' : 'bg-light')} style={{'height': '3px','width': '100%'}}></div> : '' }
                        </>
                    );
                })
            }
        </div>
    );
}

export default NumericStepper;