import React, { useEffect, useState } from 'react';
import AuthLayout from '../../components/layout/AuthLayout';
import { useHistory } from 'react-router-dom';
import { apiSaveBasicDetail, getApiCityByRid, getApiCountryByRid, getCityNames, getCountryNames, getDataDictionaryValues, getUserDetails } from '../../apis/apis';
import { useSnackbar } from '../../../context/SnackbarContext';
import { DD_TYPE_INVESTOR_TYPE } from '../../../utils/constants/constants';
import { Autocomplete, CircularProgress, Popper, TextField } from '@mui/material';

const CommunicationDetails = () => {
    let history = useHistory();
    const { showSnackbar } = useSnackbar();

    const [data, setData] = useState({
        fullName: "",
        dobDoi: "",
        investorType: null,
        address: "",
        countryRid: 0,
        cityRid: 0,
        stateRid: "",
        countryName: "",
        cityName: "",
        countryCode: "",
    });
    const [investorTypeList, setInvestorTypeList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreCity, setHasMoreCity] = useState("");
    const [inputValue, setInputValue] = useState("");
    const listboxRef = React.useRef();
    const [isBottom, setIsBottom] = useState(false);

    const handle = (e) => {
        const { id, value } = e.target;
        const newData = { ...data };

        if (id === 'countryRid') {
            newData[id] = parseInt(value, 10);
        } else {
            newData[id] = value;
        }

        setData(newData);
    };

    // Fetch the country by RID if it's available
    const getCountriesByRid = (countryRid) => {
        getApiCountryByRid(countryRid)
            .then((response) => {
                setData((prevData) => ({
                    ...prevData,
                    countryRid: response?.countryRid || 0,
                    countryName: response?.name || "",
                }));
                if(!data?.cityRid){
                    getCities(response?.countryRid,1,"",false)
                }
                setCountryList((prevList) => {
                    const isPresent = prevList.some((country) => country.countryRid === response?.countryRid);
                    if (!isPresent && response?.countryRid) {
                        return [response, ...prevList];
                    }
                    return prevList;
                });
            })
            .catch((error) => {
                console.error("Error fetching country :", error);
            });
    };

    const getCityByRid = (cityRid) => {
        getApiCityByRid(cityRid)
            .then((data) => {
                if (data) {
                    setCityList([])
                    setData((prevInvestor) => ({
                        ...prevInvestor,
                        cityRid: data?.cityRid,
                        cityName: data?.cityName,
                    }));
                    setInputValue(data?.name);
                    setCityList((prevList) => {
                        const isCityPresent = prevList.some((city) => city.cityRid === data.cityRid);
                        if (!isCityPresent) {
                            return [data, ...prevList];
                        }
                        return prevList;
                    });
                }
            })
            .catch((error) => {
                console.error('Error fetching city :', error);
            });
    };

    useEffect(() => {
            if (data?.cityRid) {
                getCityByRid(data?.cityRid);
            }
    }, [data?.cityRid]);

    useEffect(() => {
        getCountries();
        if (data?.countryRid) {
            getCountriesByRid(data.countryRid);
        }
    }, [data?.countryRid]);

    const fetchUserData = async () => {
        const userDetails = await getUserDetails();

        if (userDetails.status) {
            const { address, fullName, dobDoi, investorType, countryCode, cityRid, countryRid, stateRid, countryName, cityName } = userDetails.data;

            setData({
                fullName: fullName,
                dobDoi: dobDoi?.split(' ')[0],
                investorType: investorType,
                address: address,
                cityRid: cityRid,
                countryRid: countryRid,
                stateRid: stateRid,
                countryName: countryName,
                cityName: cityName,
                countryCode: countryCode,
            });
        }
    };

    const getDataDictionary = () => {
        getDataDictionaryValues()
            .then((data) => {
                const invTypes = data.filter((item) =>
                    item.ddType === DD_TYPE_INVESTOR_TYPE
                );
                setInvestorTypeList(invTypes);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getCountries = () => {
        const countryCode = localStorage.getItem("dialCode");
        getCountryNames(countryCode)
            .then((res) => {
                setCountryList(res);
            })
            .catch((error) => {
                console.error("Error fetching countries:", error);
            });
    };

    const submit = (e) => {
        e.preventDefault();

        if (!data?.dobDoi || data.dobDoi.trim() === "") {
            showSnackbar("Please select DOB/DOI");
            return;
        }

        if (!data.investorType) {
            showSnackbar("Please select investor type");
            return;
        }

        if (!data.address?.trim()) {
            showSnackbar("Please enter an address");
            return;
        }

        if (!data.countryRid) {
            showSnackbar("Please select a country");
            return;
        }

        if (!data.cityRid) {
            showSnackbar("Please select a city");
            return;
        }

        setIsLoading(true);

        apiSaveBasicDetail(data)
            .then(() => {
                history.replace("/user/bank-details");
            })
            .catch((err) => {
                showSnackbar(err);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setCurrentPage(1)
        fetchUserData();
        getDataDictionary();
    }, []);

    const getCities = (countryRid = data?.countryRid, page = 1, searchQuery = "", loadMore = false) => {
        setLoading(true);
        getCityNames(countryRid, page, 20, searchQuery)
            .then((newCities) => {
                if (newCities && newCities.length > 0) {
                    setHasMoreCity("")
                    if (loadMore) {
                        setCityList((prevList) => {
                            const uniqueCities = Array.from(
                                new Set([...prevList, ...newCities].map((city) => city.cityRid))
                            ).map((cityRid) => [...prevList, ...newCities].find((city) => city.cityRid === cityRid));
                            return uniqueCities;
                        });
                        // Assuming each list item has a fixed height (for example, 40px).
                        const itemHeight = 30; // Adjust this value to match your list item height.
                        setTimeout(() => {
                            const newScrollHeight = listboxRef.current.scrollHeight;
                            const previousScrollHeight = listboxRef.current.scrollHeight - newScrollHeight;

                            const itemCount = cityList.length;
                            const scrollOffset = itemCount * itemHeight;

                            listboxRef.current.scrollTop = scrollOffset;
                        }, 0.5);
                    } else {
                        setCityList(newCities)
                    }
                } else {
                    setHasMoreCity("true")
                }
            })
            .catch((e) => {
                console.error('Error fetching cities:', e);
            })
            .finally(() => {
                setLoading(false);
                setIsBottom(false);
            });
    };

    const loadMoreCities = (e) => {
        const listBox = listboxRef.current;
        const isAtBottom = listBox.scrollHeight - listBox.scrollTop <= listBox.clientHeight + 10;
        if (isAtBottom && !loading) {
            setIsBottom(true);
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            getCities(data?.countryRid, currentPage + 1, searchQuery, true);
        }
    };

    useEffect(() => {
        // After loading new cities, adjust the scroll position 0.5%  up from the bottom to avoid scroll reset to top
        if (listboxRef?.current) {
            if (isBottom) {
                const newItemsHeight = listboxRef?.current?.scrollHeight;
                const offset = newItemsHeight * 0.05;
                listboxRef.current.scrollTop = newItemsHeight - offset;
            }
        }
    }, [isBottom]);

    useEffect(()=>{
        if(data?.countryRid){
            if(inputValue.length <3){
                getCities(data?.countryRid, 1, "", false)
            }
        }
    },[inputValue])

    return (
        <AuthLayout title="Other Details">
            <form onSubmit={submit}>
                <div className="form-floating mb-4 mt-2">
                    <input
                        className="form-control"
                        placeholder="DOB"
                        name="dobDoi"
                        type="date"
                        value={data.dobDoi || ""}
                        id="dobDoi"
                        onChange={handle}
                    />
                    <label htmlFor="dobDoi">DOB</label>
                </div>

                <div className="form-floating mb-4">
                    <select
                        className="form-select"
                        id="investorType"
                        name="investorType"
                        onChange={handle}
                        value={data.investorType}
                        aria-label="Investor Type"
                    >
                        <option value={null}></option>
                        {investorTypeList?.map((item) => (
                            <option key={item.ddIndex} value={item.ddIndex}>
                                {item.ddValue}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="investorType">Investor Type</label>
                </div>

                <div className="form-floating mb-4">
                    <input
                        className="form-control"
                        placeholder="Address"
                        name="address"
                        value={data.address}
                        id="address"
                        onChange={handle}
                    />
                    <label htmlFor="address">Address</label>
                </div>

                <Autocomplete
                    options={countryList || []}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.countryRid === value?.countryRid}
                    value={countryList.find((country) => country.countryRid === data.countryRid) || null}
                    onChange={(event, value) => {
                        setHasMoreCity("")
                        setCurrentPage(1)
                        setData((prevData) => ({
                            ...prevData,
                            countryName: value ? value.name : "",
                            countryRid: value ? value.countryRid : 0,
                        }));
                    }}
                    renderInput={(params) => <TextField {...params} label="Country" />}
                />
                <Autocomplete
                    className='pt-4'
                    options={
                        data?.cityRid && cityList.find((city) => city.cityRid === data?.cityRid)
                            ? cityList.filter((city) => city.cityRid === data?.cityRid)
                            : cityList
                    }
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.cityRid === value?.cityRid}
                    value={
                        cityList.find((city) => city.cityRid === data?.cityRid) ||
                        (inputValue && { name: inputValue }) ||
                        null
                    }
                    inputValue={inputValue}
                    onInputChange={(event, value, reason) => {
                        if (reason === "input") {
                            setInputValue(value);
                            setCurrentPage(1);
                            setSearchQuery(value);
                            setData({
                                ...data,
                                cityRid: 0, // Reset cityRid when the user starts typing
                            });
                            setCityList([]);
                            
                            // Make sure countryRid is defined before calling getCities
                            if (value?.length >= 3 && data?.countryRid) {
                                setTimeout(() => {
                                    getCities(data.countryRid, 1, value, false); // Only pass valid countryRid
                                }, 500);
                            }
                        } else if (reason === "clear") {
                            setInputValue("");
                            setData({
                                ...data,
                                cityRid: 0, // Reset cityRid
                            });
                            setSearchQuery("");
                        }
                    }}
                    onChange={(event, value) => {
                        setData({
                            ...data,
                            cityRid: value ? value.cityRid : "",
                            cityName: value ? value.name : "",
                            stateRid: value ? value.stateRid : "",
                        });
                        if (!value) {
                            setInputValue("")
                        } else {
                            setInputValue(value?.name)
                        }
                    }}
                    renderOption={(props, option) => (
                        <li {...props} key={option.cityRid}>
                            {option.name} {option.stateName && ` [${option.stateName}]`}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="City"
                            placeholder="Search your city"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    disablePortal
                    fullWidth
                    PopperComponent={(props) => (
                        <Popper
                            {...props}
                            style={{
                                ...props.style,
                                zIndex: 1.5,
                            }}
                        />
                    )}
                    ListboxComponent={(props) => (
                        <ul
                            {...props}
                            ref={listboxRef}
                            onScroll={hasMoreCity === "" ? loadMoreCities : undefined}
                            style={{
                                overflowY: 'auto',
                                maxHeight: 280,
                                zIndex: 2,
                            }}
                        />
                    )}
                />

                <div className="d-flex mt-4 mb-2">
                    <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2">
                        Save & Continue
                    </button>
                </div>
            </form>
        </AuthLayout>
    );
};

export default CommunicationDetails;