import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom';

import { getNominationDrawDownDetails, getUserDetails } from "../../apis/apis";
import { getUserAuth } from "../../../utils/LocalStorageUtils";
import AuthLayout from "../../components/layout/AuthLayout";
import CustomSnackbar from "../../components/Toast/CustomSnackbar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, LinearProgress } from '@mui/material';
import { setDashboardTab, FLAG_REDIRECT_FROM_DOCUMENTS, getItem } from "../../../utils/LocalStorageUtils";
import { isForeigner } from "../../../utils/StringUtil";



function NominationSuccessfull(props) {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const mcaEvent = searchParams.get('event');
    const envelopId = searchParams.get('envelopId');



    const [errorData, setErrorData] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const [nominationStatus, setNominationStatus] = useState(-1);
    const fetchUserDetails = getUserDetails();
    const fetchNominationData = async () => {

        const nominationDetails = await getNominationDrawDownDetails({
            "envelopId": envelopId
        });
        if (nominationDetails.status) {
            const { status } = nominationDetails.data;

            if (status === 1) {

                setNominationStatus(status);

                // else {
                //     history.replace("/success-lp", {"alertTitle": 'Nomination Successfully Signed'});
                // }
            } else {
                // setErrorData('MCA not signed');
                setErrorData("You need to complete the Nomination signing to proceed further with the investments");
                setOpenSnackbar(true);
                setTimeout(() => {
                    history.replace("/user/nomination");
                }, 2000);
            }
        } else {
            setErrorData(nominationDetails.description);
            setOpenSnackbar(true);
            setTimeout(() => {
                history.replace("/user/nomination");
            }, 2000);

        }
    };

    const handleContinue = async () => {
        if (isForeigner()) {
            history.replace("/user/fatca");
        } else {
            setDashboardTab(1);
            history.push("/user/home")
        }
    };

    useEffect(() => {
        setTimeout(() => {
            fetchNominationData();
        }, 2000);
    }, []);

    return (
        <AuthLayout title={nominationStatus === -1 ? '' : 'Nomination Successfully Signed'}>
            {
                nominationStatus === -1
                    ? <div className="d-flex justify-content-center">
                        <div className="loading-spinner"></div>
                    </div>
                    : <>
                        {getUserAuth() && !getItem(FLAG_REDIRECT_FROM_DOCUMENTS) ?
                            <>
                                <div className="col-4">
                                    <div className='d-flex mt-3'>
                                        <button type="button"
                                            onClick={handleContinue}
                                            className="btn btn-primary px-3 py-2">Continue</button>
                                    </div>
                                </div>
                            </>

                            : ""}
                        {getItem(FLAG_REDIRECT_FROM_DOCUMENTS) ?
                            <div className="col-4">
                                <div className='d-flex mt-3'>
                                    <button type="button"
                                        onClick={() => history.push("/user/documents/")}
                                        className="btn btn-primary px-3 py-2">Finish</button>
                                </div>
                            </div> : null
                        }
                    </>
            }
            <CustomSnackbar
                open={openSnackbar}
                severity="error"
                duration={2000}
                text={errorData}
                handleClose={handleSnackbarClose}
            />
        </AuthLayout>
    );
}

export default NominationSuccessfull;
