import React, { useEffect, useState } from 'react';
import CompanyUpdateModal from './CompanyUpdateModal';
import { apiCompanyUpdates } from '../apis/apis';
import CustomPieChart from './CustomPieChart';

function DashboardContent(props) {
    const [open, setOpen] = useState(false);
    const [companyUpdateList, setCompanyUpdateList] = useState([]);
    const [cuRid, setCuRid] = useState(null);

    const { investmentByRounds, handleTab } = props;

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const month = monthNames[date.getMonth()];
        const day = String(date.getDate()).padStart(2, '0');
        return `${month} ${day}`;
    }

    const handleOpen = (id) => {
        setOpen(true);
        setCuRid(id);
    }
    const handleClose = () => {
        setOpen(false);
        setCuRid(null);
    }

    const chartOptions = {
        labels: investmentByRounds?.map(obj => obj.invRound) ?? [],
        dataLabels: {
            enabled: false,
        },
        // plotOptions: {
        //     pie: {
        //         donut: {
        //             size: '65%'
        //         }
        //     }
        // }
    }
    const chartSeries = investmentByRounds.map(obj => obj.totCommitment)

    const getCompanyUpdateList = (params = {}) => {
        params.recLimit = 5;
        apiCompanyUpdates(params)
            .then((data) => {
                setCompanyUpdateList(data.data.companyUpdatesList);
            }).catch((e) => {
                console.log(e);
            })
    }

    useEffect(() => {
        getCompanyUpdateList();
    }, []);

    return (
        <div className="row my-5">

            <CompanyUpdateModal
                open={open}
                closeAction={handleClose}
                companyUpdateRid={cuRid}
            />

            <div className="col-12 col-md-6">
                <div className="card h-100 p-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="fs-large-semibold mb-0">Invested by Round</p>
                        {/* <p className="fs-normal-medium text-purple">See More Insights</p> */}
                    </div>
                    <div className="row justify-content-between mx-0">
                        <CustomPieChart investmentByRounds={investmentByRounds} />
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6">
                <div className="card h-100 p-4">
                    <div className="d-flex gap-3 justify-content-between align-items-center">
                        <p className="fs-large-semibold">Most Recent Activity</p>
                        <p className="fs-normal-medium text-purple custom-link" onClick={() => handleTab(2)}>
                            See More Activity
                        </p>
                    </div>
                    <table className="table p-0 m-0">
                        {companyUpdateList.map((data, key) => {
                            return (
                                <tr className="d-flex align-items-center" key={key}>
                                    <td className="d-flex pb-0" >
                                        <p className="p-0 m-0">{formatDate(data.publishDate)}</p>
                                    </td>
                                    <td className="d-flex pb-0 flex-grow-1">
                                        <p className="pointer p-0 m-0" onClick={() => handleOpen(data.cuRid)}>{data.title}</p>
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </div>
            </div>
        </div>
    );
}

export default DashboardContent;