import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import { apiTransactions } from '../apis/apis';
import { TableHeadButton } from "../../components/ui/TableHeadButton";
import { TRANSACTION_FAILED, TRANSACTION_INITIATED, TRANSACTION_PENDING, TRANSACTION_SUCCESS } from '../../utils/constants/constants';
import { formatDateTime, toLocalePrice } from '../../utils/StringUtil';

const Transactions = () => {
    const [transactionsList, setTransactionsList] = useState([]);

    const getTransactions = async () => {
        try {
            const data = await apiTransactions();
            setTransactionsList(data.transactions);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getTransactions();
      }, []);

  return (
    <>
        <NavBar />

        <div className="container">
            <h3 className="fs-x-large-semibold mt-4 mt-md-5 mb-0">Payment History</h3>

              <table className="table p-0 m-0 my-5">
                  <tr className="fs-med-regular text-muted">
                      <th scope="col">
                          <TableHeadButton
                              label="Transaction ID"
                              activeUp={false}
                              activeDown={false}
                          />
                      </th>
                      <th scope="col">
                          <TableHeadButton
                            label="Status"
                            activeUp={false}
                            activeDown={false}
                            align="center"
                          />
                      </th>
                      <th scope="col">
                          <TableHeadButton
                              label="Amount"
                              activeUp={false}
                              activeDown={false}
                              leftAlign
                              hideControls
                          />
                      </th>
                      <th scope="col">
                          <TableHeadButton
                              label="Date & Time"
                              activeUp={false}
                              activeDown={false}
                          />
                      </th>
                      <th scope="col">
                          <TableHeadButton
                              label="Transaction Type"
                              activeUp={false}
                              activeDown={false}
                          />
                      </th>
                  </tr>

                  {
                      transactionsList?.map((data, key) => (
                        <>
                            <tr
                                className="border-bottom pointer row-hover"
                                onClick={(e) => { }}
                                key={key}
                            >
                                <td>
                                    <div className="d-flex gap-2 align-items-center p-0 mx-0 my-1">
                                        <div className="d-flex flex-column ps-1">
                                            <p className="fs-6 p-0 m-0">{data.transactionId ? data.transactionId : 'N/A'}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className="text-center p-0 m-0">
                                        <span
                                            className={` fs-normal-medium transaction-status ${data.tranStatus === TRANSACTION_SUCCESS ? "success" : data.tranStatus === TRANSACTION_FAILED ? "failed" : data.tranStatus === TRANSACTION_PENDING ? "pending" : ""
                                                }`}
                                        >
                                            {data.tranStatus === TRANSACTION_SUCCESS && "Success"}
                                            {data.tranStatus === TRANSACTION_FAILED && "Failed"}
                                            {data.tranStatus === TRANSACTION_PENDING && "Pending"}
                                        </span>
                                    </p>
                                </td>
                                <td>
                                    <p className="text-end p-0 m-0">
                                        {toLocalePrice(data.amt)}
                                    </p>
                                </td>
                                <td>
                                    <p className="p-0 m-0">
                                        {data.tranDatetime &&
                                            formatDateTime(data.tranDatetime, true)}
                                    </p>
                                </td>
                                <td>
                                    <p className="p-0 m-0">
                                        {
                                            data.tranType === 'WALLET_TOPUP'
                                                ? 'Wallet Topup':
                                                data.tranType === 'COMMITMENT'?'Commitment'
                                                : data.tranType === 'OFFLINE'
                                                    ? 'Offline'
                                                    : ''
                                        }
                                    </p>
                                </td>
                            </tr>
                        </>
                      ))
                  }
              </table>
        </div>
    </>
  );
}

export default Transactions;