import React, { useEffect } from 'react'
import { silentLogin } from '../apis/apis'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useSnackbar } from '../../context/SnackbarContext'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setUserAuth,setItem,FLAG_REDIRECT_FROM_PREVIEW } from '../../utils/LocalStorageUtils';

const Preview = () => {

    const { id } = useParams()
    const history = useHistory()
    const { showSnackbar } = useSnackbar()

    const saveUserDetails = (data) => {
        // localStorage.setItem("firstName", data.firstName);
        // localStorage.setItem("lastName", data.lastName);
        localStorage.setItem("fullName", data.user.fullName);
        localStorage.setItem("email", data.user.email);
        localStorage.setItem("mobileNumber", data.user.mobile);
        setItem(FLAG_REDIRECT_FROM_PREVIEW,true);
        history.replace("/user/home");
    }

    useEffect(() => {

        localStorage.clear()

        silentLogin(id).then(res => {
            if (res.status) {
                setUserAuth(1) //TODO : handle this in interceptor
                saveUserDetails(res.data)
                localStorage.setItem('Show_Opportunities', 1);
            } else {
                showSnackbar(res.description)
            }
        }).catch(e => {
            showSnackbar(e)
        })

    }, [])
    return (
        <div className='preview-loader-wrapper'>
            <img className="logo" src={"/logo2.svg"} alt={"logo"} />
        </div>
    )
}
export default Preview

