import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/layout/AuthLayout";
import { useHistory } from "react-router-dom";
import { apiDematInfo, apiNotifyAdmin, getDataDictionaryValues, getUserDetails, uploadFile } from "../../apis/apis";
import { DD_TYPE_DP_NAMES } from "../../../utils/constants/constants";
import { AttachFile, Close } from "@mui/icons-material";
import { setDashboardTab } from "../../../utils/LocalStorageUtils";
import CustomSnackbar from "../../components/Toast/CustomSnackbar";
import { isValidCdsldpId, isValidNsdldpId, isValidClientId } from "../../../utils/StringUtil";

const DematDetails = () => {
  let history = useHistory();
  const [bankNameList, setBankNameList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [dpNameList, setDpNameList] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [showOtherInput, setShowOtherInput] = useState(false);

  const [data, setData] = useState({
    dpName: null,
    dpDdIndex: null,
    otherDematName: null,
    nsdlCdsl: null,
    dpId: null,
    dpClientId: null,
    dematAccNo: null,
    firstHolderName: null,
    cmlCopyObject: null
  });
  const [showAccountNo, setShowAccountNo] = useState(false);

  const handleOtherInputChange = (event) => {
    setData({ ...data, otherDematName: event.target.value, dpName: null });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handle = (e) => {
    const { id, value } = e.target;

    if (id === 'dpDdIndex') {
      if (value === 'other') {
        setShowOtherInput(true);
        setData((prevData) => ({ ...prevData, dpDdIndex: 0, otherDematName: null, dpName: null }));
      } else {
        setShowOtherInput(false);
        setData((prevData) => ({ ...prevData, dpDdIndex: value || null, otherDematName: "" }));
      }
    } else {
      setData((prevData) => ({ ...prevData, [id]: value || "" }));
    }
    if ((id === 'dpId' || id === 'dpClientId') && value.length === 8) {
      const newDpId = id === 'dpId' ? value : data?.dpId;
      const newDpClientId = id === 'dpClientId' ? value : data?.dpClientId;

      if (newDpId?.length === 8 && newDpClientId?.length === 8) {
        setShowAccountNo(true);
        setData((prevData) => ({
          ...prevData,
          dematAccNo: newDpId + newDpClientId,
        }));
      }
    }
  };


  const submit = (e) => {
    e.preventDefault();

    const dpDdIndex = data?.dpDdIndex ?? null;

    if ((dpDdIndex === 0 || dpDdIndex === null || dpDdIndex === '---') && !data?.otherDematName) {
      setToastStatus("error");
      setErrorData("Please select or enter a DP name");
      setOpenSnackbar(true);
      return;
    }

    if (data?.nsdlCdsl === "" || data?.nsdlCdsl === null) {
      setToastStatus("error");
      setErrorData("Please select DP Type");
      setOpenSnackbar(true);
      return;
    }

    if (data?.nsdlCdsl === "NSDL") {
      if (!isValidNsdldpId(data?.dpId)) {
        setToastStatus("error");
        setErrorData("Invalid DP ID");
        setOpenSnackbar(true);
        return;
      }
    }

    if (data?.nsdlCdsl === "CDSL") {
      if (!isValidCdsldpId(data?.dpId)) {
        setToastStatus("error");
        setErrorData("Invalid DP ID");
        setOpenSnackbar(true);
        return;
      }
    }

    if (!isValidClientId(data?.dpClientId)) {
      setToastStatus("error");
      setErrorData("Invalid Client ID");
      setOpenSnackbar(true);
      return;
    }

    if (data?.dpId?.length !== 8) {
      setToastStatus("error");
      setErrorData("DP Id should be 8 characters in length");
      setOpenSnackbar(true);
      return;
    }

    if (data?.dpClientId?.length !== 8) {
      setToastStatus("error");
      setErrorData("Client Id should be 8 characters in length");
      setOpenSnackbar(true);
      return;
    }

    if (data?.dpId?.length === 8 && data?.dpClientId?.length === 8) {
      setShowAccountNo(true);
      setData((prevData) => ({ ...prevData, dematAccNo: data?.dpId + data?.dpClientId }));
    }

    setIsLoading(true);
    apiDematInfo(data)
      .then(() => {
        history.push("/user/agreement");
      })
      .catch((err) => {
        setToastStatus("error");
        setErrorData(err);
        setOpenSnackbar(true);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchDematData = async () => {
    const userDetails = await getUserDetails();
    if (userDetails?.status) {
      const {
        dematAccNo,
        dpName,
        dpId,
        dpClientId,
        dpDdIndex,
        firstHolderName,
        cmlCopyObject,
        nsdlCdsl
      } = userDetails?.data;
      setData({
        dematAccNo,
        dpName,
        dpId,
        dpClientId,
        dpDdIndex,
        firstHolderName,
        cmlCopyObject,
        nsdlCdsl
      });
    }
  };

  const getDataDictionary = () => {
    getDataDictionaryValues()
      .then((data) => {
        const dpNames = data.filter((item) =>
          item.ddType === DD_TYPE_DP_NAMES
        )

        setDpNameList(dpNames);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadDocument = (e) => {
    setIsFileLoading(true);
    uploadFile(e.target.files[0])
      .then((res) => {
        setIsFileLoading(false);
        setData(prevState => ({
          ...prevState,
          [e.target.name]: res.data,
        }));
      })
      .catch((err) => {
        setIsFileLoading(false);
        setToastStatus("error");
        setErrorData(err);
        setOpenSnackbar(true);
      });
  }
  const deleteDocument = (key) => {
    setData(prevState => ({
      ...prevState,
      [key]: null,
    }));
  }

  useEffect(() => {
    fetchDematData();
    getDataDictionary();
  }, []);

  const notifyAdmin = () => {
    apiNotifyAdmin({
      contextType: 3
    })
      .then(() => {
        setToastStatus("success");
        setErrorData("Success");
        setOpenSnackbar(true);
        setTimeout(() => {
          history.replace("/user/contact-team");
        }, 2000);
      })
      .catch((err) => {
        setToastStatus("error");
        setErrorData(err);
        setOpenSnackbar(true);
      });
  }

  return (
    <AuthLayout title="Demat Details">
      <form onSubmit={submit}>

        <div className="form-floating mb-3">
          <select
            className="form-select"
            id="dpDdIndex"
            name="dpDdIndex"
            onChange={handle}
            value={data.dpDdIndex || null}
          >
            <option value={null}>---</option>
            {
              dpNameList?.map(item => <option value={item.ddIndex}>{item.ddValue}</option>)
            }
            <option value="other">Other</option>
          </select>
          <label htmlFor="dpDdIndex">Depository Participant (DP) Name</label>

          {showOtherInput && (
            <div className="form-floating mb-3 mt-3">
              <input
                className="form-control"
                placeholder="Other DP Name"
                onChange={handleOtherInputChange}
                name="otherBankName"
                value={data.otherDematName || ""}
                id="otherDPName"
                type="text"
              />
              <label htmlFor="otherDPName">Enter Other DP Name</label>
            </div>
          )}

        </div>

        <div className="form-floating mb-3">
          <select
            className="form-select"
            id="nsdlCdsl"
            name="nsdlCdsl"
            onChange={handle}
            value={data.nsdlCdsl}
          >
            <option value="">---</option>
            <option value="NSDL">NSDL</option>
            <option value="CDSL">CDSL</option>
          </select>
          <label htmlFor="nsdlCdsl">NSDL / CDSL</label>
        </div>

        <div className="form-floating mb-3">
          <input
            className="form-control"
            type="text"
            id="dpId"
            name="dpId"
            placeholder="DP ID"
            value={data.dpId}
            onChange={handle}
            maxLength={8}
          />
          <label htmlFor="dpId">DP ID</label>
        </div>

        <div className="form-floating mb-3">
          <input
            className="form-control"
            type="text"
            id="dpClientId"
            name="dpClientId"
            placeholder="Client ID"
            value={data.dpClientId}
            onChange={handle}
            maxLength={8}
          />
          <label htmlFor="dpClientId">Client ID</label>
        </div>
        {showAccountNo && (
          <div className="form-floating mb-3">
            <input
              readOnly
              className="form-control"
              type="text"
              id="dematAccNo"
              name="dematAccNo"
              placeholder="Demat Account Number"
              value={data.dematAccNo}
              onChange={handle}
              maxLength={16}
            />
            <label htmlFor="dematAccNo">Demat Account Number</label>
          </div>
        )}

        <div className="form-floating mb-3">
          <input
            className="form-control"
            type="text"
            id="firstHolderName"
            name="firstHolderName"
            placeholder="First Holder Name"
            value={data.firstHolderName}
            onChange={handle}
          />
          <label htmlFor="firstHolderName">First Holder Name</label>
          <div className="form-text">
            Must be same as Investor name
          </div>
        </div>

        <div className="d-flex gap-2 flex-column mb-3">
          <input
            className="d-none"
            id={`input-file`}
            type="file"
            name="cmlCopyObject"
            onChange={uploadDocument}
          />
          <span className="fs-med-medium">CML COPY</span>
          <div className="d-flex align-items-center">
            {data.cmlCopyObject ? (
              <div className="position-relative d-flex gap-2 justify-content-center align-items-center flex-column border p-4 w-100">
                <div className="position-absolute top-0 end-0 p-1 pointer" onClick={() => deleteDocument('cmlCopyObject')}>
                  <Close />
                </div>

                <AttachFile />
                <span className="fs-normal-med">{data.cmlCopyObject?.actualFileName}</span>
              </div>
            ) : (
              <button type="button" disabled={isFileLoading || isLoading} className="btn btn-secondary" onClick={() => document.getElementById(`input-file`).click()}>
                Add File
              </button>
            )}
          </div>
        </div>
        <div className="d-flex mt-3">
          <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2">
            Save & Continue
          </button>
          <button type="button" className="btn btn-light px-3 ms-3 py-2"
            onClick={() => {
              setDashboardTab(1);
              history.push("/user/agreement")
            }
            }>
            I'll Fill This Later
          </button>
        </div>

        <p className="p-0 mt-3">If you need assistance, <span className='text-primary custom-link' onClick={() => notifyAdmin()}>Admin Help</span></p>
      </form>
      <CustomSnackbar
        open={openSnackbar}
        severity={toastStatus}
        duration={4000}
        text={errorData}
        handleClose={handleSnackbarClose}
      />
    </AuthLayout>
  );
};

export default DematDetails;
