import React from "react";
import { useHistory } from "react-router-dom";
import CardLayout from "../components/layout/CardLayout";

function CongratulationsSIP() {
  const history = useHistory();

  return (
    <CardLayout showBackButton={false}>
        <div className="container mt-5">
                <h3 className="title-md text-center mb-4">Congratulations!<br/>Your payment is successful!</h3>

                <div className="card-raised mx-auto w-75 p-5 mb-5">
                    <h3 className="card-title-md text-center">Our Representative will<br />contact you soon</h3>
                </div>
            </div>

            <div className="d-flex justify-content-center">
              <button className="color-btn mx-auto" onClick={()=>history.replace("/user/home/")}>Go to Portfolio Page</button>
            </div>
    </CardLayout>
  );
}

export default CongratulationsSIP;