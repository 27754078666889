export const formattedCurrency = (amount) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,

  }).format(amount).replace("₹","₹ ");
};

export const formattedoCurrencyNumbering = (amount) => {
  if (isNaN(amount)) {
    throw new Error("Invalid amount");
  }
  amount = Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
  return amount.toString();
}

export const convertToCurrencyNumbering = (amount) => {
  if (isNaN(amount)) {
    throw new Error("Invalid amount");
  }
  
  let suffix = "";
  if (amount >= 10000000) {
    amount /= 10000000;
    suffix = " Cr";
  } else if (amount >= 100000) {
    amount /= 100000;
    suffix = " L";
  } else if (amount >= 1000) {
    amount /= 1000;
    suffix = " K";
  }

  amount = Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
  
  return amount.toString() + suffix;
}
