import React, { useState, useEffect } from "react";
import LayoutKyc from "../components/layout/LayoutKYC";
import { useHistory } from "react-router-dom";
// import { getKyc, postKyc, verifyPan } from "../apis/apis";
import { apiNotifyAdmin, getUserDetails, verifyPan } from "../apis/apis";
import CustomSnackbar from "../components/Toast/CustomSnackbar";
import NumericStepper from "../components/NumericStepper";
import CardLayout from "../components/layout/CardLayout";
import { CONTACT_TEAM_MESSAGE } from "../../utils/constants/constants";
import AuthLayout from "../components/layout/AuthLayout";
// import { CreateDigio } from "../Digio/CreateDigio";

function KyCpage() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  // const email = localStorage.getItem("email");

  const [showChangeName, setShowChangeName] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const [val, setData] = useState({
    pan_no: "",
  });

  const handle = (e) => {
    const newdata = { ...val };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  };
  const submit = async (e) => {
    setIsLoading(true)
    setTimeout(() => {

    }, 4000)
    e.preventDefault()

    verifyPan(val)
      .then((res) => {
        const { data } = res;

        if (data.status === 1) {
          setToastStatus("success");
          setErrorData('PAN Verified');
          setOpenSnackbar(true);
          setIsLoading(false);
          setTimeout(() => {
            history.replace("/user/investmet-method");
          }, 1000);
        } else if (data.status === -1) {
          setToastStatus("error");
          setErrorData('Your Name does not match with CKYC data');
          setOpenSnackbar(true);
          setIsLoading(false);

          setShowChangeName(true);
        } else {
          setToastStatus("error");
          setErrorData('CKYC not available');
          setOpenSnackbar(true);
          setIsLoading(false);

          history.replace('/user/ckyc-not-available');
        }
      })
      .catch((err) => {
        setToastStatus("error");
        setErrorData(err);
        setOpenSnackbar(true);
        setIsLoading(false);
      }).finally = () => {
        setIsLoading(false)
      };
    // try {
    //   const KycData = await postKyc();
    //   console.log(KycData);
    //   if (KycData.status) {
    //     console.log(KycData.data);
    //     const digioData = await CreateDigio(
    //       KycData.data.id,
    //       email,
    //       KycData.data.access_token.id
    //     );
    //     console.log(digioData);
    //     if (digioData.status === false) {
    //       console.log(digioData);
    //       setToastStatus("error");
    //       setErrorData(digioData.description);
    //       setOpenSnackbar(true);
    //     }
    //     if (digioData.digio_doc_id) {
    //       setToastStatus("success");
    //       setErrorData("KYC Completed");
    //       setOpenSnackbar(true);
    //       try {
    //         const { data, description } = await getKyc();
    //         console.log(data);

    //         if (
    //           data.status === "approved" ||
    //           data.status === "approval_pending"
    //         ) {
    //           history.push("/user/kyc-confirmation");
    //         } else {
    //           setErrorData(description || "KYC is not approved.");
    //           setOpenSnackbar(true);
    //           // history.push("/user/agreement");
    //           console.log(data);
    //         }
    //       } catch (err) {
    //         console.log(err);
    //       }
    //     }
    //   } else {
    //     setToastStatus("info");
    //     setErrorData(KycData.description || "Failed to submit KYC.");
    //     setOpenSnackbar(true);
    //   }
    // } catch (err) {
    //   setErrorData(err);
    //   setOpenSnackbar(true);
    // } finally {
    //   setIsLoading(false);
    // }

    // try {
    //   const { data, description } = await getKyc();
    //   console.log(data);

    //   if (data.status === "approved") {
    //     history.push("/user/kyc-confirmation");
    //   } else {
    //     setErrorData(description || "KYC is not approved.");
    //     setOpenSnackbar(true);
    //     // history.push("/user/agreement");
    //     console.log(data);
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  };

  const contactTeam = () => {
    setToastStatus("error");
    setErrorData(CONTACT_TEAM_MESSAGE);
    setOpenSnackbar(true);
  }

  const fetchUserData = async () => {
    const userDetails = await getUserDetails();

    if (userDetails.status) {
      const { pan } = userDetails.data;

      if (pan !== null) {
        const newdata = { ...val };
        newdata.pan_no = pan;
        setData(newdata);
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const notifyAdmin = () => {
    apiNotifyAdmin({
      contextType: 1
    })
      .then((res) => {
        setToastStatus("success");
        setErrorData('Success');
        setOpenSnackbar(true);
        setIsLoading(false);
        setTimeout(() => {
          history.replace("/user/contact-team");
        }, 2000);
      })
      .catch((err) => {
        setToastStatus("error");
        setErrorData(err);
        setOpenSnackbar(true);
      });
  }

  return (
    <AuthLayout title="CKYC Verification">
      <form onSubmit={submit}>

        <p className='fs-normal-medium text-primary mb-4'>PAN is mandatory for making investments in India through an Angel Fund</p>

        <div className="form-floating mb-3">
          <input
            className='form-control'
            placeholder="PAN Number"
            onChange={handle}
            name='pan_no'
            value={val.pan_no}
            id="pan_no"
          />
          <label htmlFor="pan_no">PAN Number</label>
        </div>

        {
          showChangeName
            ? <p className='fs-normal-medium text-danger mb-3'>
              Your Name does not match with CKYC data
              <span className='text-primary custom-link ms-2' onClick={() => history.replace('/user/update-name')}>Update name.</span>
            </p>
            : ''
        }

        <div className='d-flex gap-3 align-items-center mt-3'>
          {
            isLoading ? <div className="d-flex justify-content-center">
              <div className="loading-spinner"></div>
            </div> : <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2"
            >Verify</button>
          }
          {toastStatus === "error" ?
            <p className="p-0 m-0"><span className='text-primary custom-link' onClick={() => notifyAdmin()}>Help Me complete my KYC</span></p>:
            <p className="p-0 m-0">I don't have PAN. <span className='text-primary custom-link' onClick={() => notifyAdmin()}>Help me setup.</span></p>
          }
          
        </div>
      </form>

      <CustomSnackbar
        open={openSnackbar}
        severity={toastStatus}
        duration={4000}
        text={errorData}
        handleClose={handleSnackbarClose}
      />
    </AuthLayout>
    // <CardLayout>
    //   <div className="container mt-5">
    //     <NumericStepper />
    //     <h3 className="title-md text-center mb-5">CKYC Verification</h3>
    //     <div className="d-flex justify-content-center input-box mb-5">
    //       <input
    //         type="text"
    //         id="pan_no"
    //         className="col-12 col-md-6 item-primary fs-5"
    //         placeholder="PAN Number"
    //         onClick={() => {
    //           setErrorData("");
    //         }}
    //         onChange={(e) => {
    //           handle(e);
    //         }}
    //       />
    //     </div>
    //   </div>
    //   <div className="d-flex justify-content-center mb-5">
    //     <button className="color-btn mx-auto" onClick={submit} disabled={isLoading}>
    //       {isLoading ? "Loading..." : "VERIFY"}
    //     </button>
    //   </div>  

    //   <CustomSnackbar
    //     open={openSnackbar}
    //     severity={toastStatus}
    //     duration={4000}
    //     text={errorData}
    //     handleClose={handleSnackbarClose}
    //   />
    // </CardLayout>
  );
}

export default KyCpage;
