import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUserAuth } from "../utils/LocalStorageUtils";

const PublicRoute = (props) => {
  let auth = getUserAuth();

  return (
    <Route
      path={props.path}
      render={({ location }) =>
        auth ? (
          props.path === '/success-lp' ||
            props.path === '/consent-success' ||
            props.path === '/agreement-lp' ||
            props.path.includes('payment-status') ||
            props.path.includes('commitment-payment') ||
            props.path.includes('preview') ||
            props.path.includes('show-interest')
            ? <props.component /> :
            <Redirect
              to={{ pathname: "/user/home", state: { from: location } }}
            />
        ) : (
          <props.component />
        )
      }
    />
  );
};
export default PublicRoute;
