import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { apiTransactioWalletTopup,  apiTransactionDetails,apiGetConvenienceFee } from "../apis/apis";
import { formattedCurrency } from "../../utils/userUtilities";
import CardLayout from "../components/layout/CardLayout";
import CustomSnackbar from "../components/Toast/CustomSnackbar";

function PaymentPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const oppRid = searchParams.get('oppRid');
    const amount = searchParams.get('amount');
    const tranType = searchParams.get('tranType');

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [toastStatus, setToastStatus] = useState("error");

    const [paymentType, setPaymentType] = useState('Online Payment');
    const [convenienceFee, setConvenienceFee] = useState(0);


    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const makePayment = (data) => {
        var flow_config = {
            merchantId: data.merchantId,
            bdOrderId: data.bdorderId,
            authToken: data.token,
            childWindow: true,
            returnUrl: '',
			crossButtonHandling: 'Y',
			retryCount: 0
        }; 

        var responseHandler = function (txn) {
            if(txn.txnResponse){
                getTransactionDetails(data.orderId);
            } else {
                setErrorMessage("Transaction Not Completed");
                setToastStatus("error");
                setOpenSnackbar(true);
            }
        };

        var config = {
            responseHandler: responseHandler,
            flowConfig: flow_config,
            flowType: "payments"
        };
        window.loadBillDeskSdk(config);
    }

    const handleCreateTransaction = () => {
        setIsLoading(true);
        apiTransactioWalletTopup({
            "amount": amount,
        }).then((res) => {
            setIsLoading(false);
            makePayment(res);
        }).catch((err) => {
            setIsLoading(false);
            setErrorMessage(err.toString());
            setToastStatus("error");
            setOpenSnackbar(true);
        });
    }

    const getTransactionDetails = (orderid) => {
        setIsLoading(true);
        apiTransactionDetails(orderid)
        .then((res) => {
            setIsLoading(false);
            setTimeout(() => {
                history.replace({
                    pathname: "/user/payment-status",
                    state: {transactionResponse: res}
                });
            }, 1000);
        }).catch((err) => {
            setIsLoading(false);
            setErrorMessage(err.toString());
            setToastStatus("error");
            setOpenSnackbar(true);
        });
    }
    const getConvenienceFee = () => {
        apiGetConvenienceFee()
            .then((data) => {
                setConvenienceFee(data.convenienceFee);
            })
    }

    const handleProceed = () => {
        if(tranType === process.env.REACT_APP_TYPE_WALLET_TOPUP){
            handleCreateTransaction();
        }
    }

    useEffect(() => {
        if(tranType === process.env.REACT_APP_TYPE_SIP_PG){
            setPaymentType('First SIP Payment');
        }
        if(tranType === process.env.REACT_APP_TYPE_WALLET_TOPUP){
            setPaymentType('Wallet top-up');
            getConvenienceFee();
        }
        if(tranType === process.env.REACT_APP_TYPE_LUMPSUM_PG){
            setPaymentType('Lumpsum Payment');
        }
        
    }, []);
    

    return (
        <CardLayout>
            <div className="container mt-5">
                <h3 className="title-md text-center text-uppercase mb-5">
                    {paymentType}
                </h3>
                <div className="border rounded mx-auto w-75 p-4 mb-5">

                    <h3 className="fs-title-semibold text-secondary text-center p-0 m-0 mb-2">
                        Payment Summary
                    </h3>
                    <div className="d-flex justify-content-center flex-column mx-auto col-8">
                        <table className="table table-bordered ">
                            <tbody>
                                <tr>
                                    <td><p className="fs-large-semibold">Top Up Amount</p></td>
                                    <td><p className="fs-5 text-end p-0 m-0"><b> {formattedCurrency(amount)}</b> </p></td>
                                </tr>
                                
                                    <tr>
                                        <td><p className="fs-large-semibold">Convenience Fees</p></td>
                                        <td><p className="fs-5 text-end p-0 m-0"><b>+ {formattedCurrency(convenienceFee)} </b></p></td>
                                    </tr> 
                                
                                <tr>
                                    <td><p className="fs-large-semibold">Total Amount</p></td>
                                    <td><p className="fs-5 text-end p-0 m-0"><b> {formattedCurrency(parseFloat(amount)+convenienceFee)}</b></p></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="border rounded mx-auto w-75 p-5 mb-5">
                    <h3 className="fs-2 text-secondary text-center p-0 m-0">
                        Amount payable: <b>{formattedCurrency(parseFloat(amount)+convenienceFee)}</b>
                    </h3>
                </div>
            </div>

            <div className="d-flex justify-content-center mb-5">
                <button className="btn btn-primary px-4 py-2 mb-5" onClick={handleProceed} disabled={isLoading}>
                    {isLoading ? "Loading..." : "PAY NOW"}
                </button>
            </div>

            <CustomSnackbar
                open={openSnackbar}
                severity={toastStatus}
                duration={4000}
                text={errorMessage}
                handleClose={handleSnackbarClose}
            />
        </CardLayout>
    );
}

export default PaymentPage;