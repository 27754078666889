import React from "react";
import { getUserDetails, getWallet } from "../apis/apis";
import { useEffect } from "react";
import { useState } from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useHistory } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { formattedoCurrencyNumbering } from "../../utils/userUtilities";
import { CONTACT_TEAM_MESSAGE } from "../../utils/constants/constants";
import CustomSnackbar from "../components/Toast/CustomSnackbar";
import CardLayout from "../components/layout/CardLayout";
import InvestorDetails from "./InvestorDetails";

function MyProfile() {
  const history = useHistory();
  const [mcaStatus, setMcaStatus] = useState(-1);
  const [ckycStatus, setCkycStatus] = useState(-1);
  const [eNachStatus, setENachStatus] = useState(-1);
  const [eNachStatusMessage, setENachStatusMessage] = useState("");
  const [walletAmount, setWalletAmount] = useState(0.0);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const profileCompleteSteps = [
    {
      index: 1,
      title: "CKYC Verification",
      warningMessage: "",
      successMessage: ckycStatus ? "CKYC Verified" : "",
      status: ckycStatus,
    },
    {
      index: 2,
      title: "Master Contribution Agreement",
      warningMessage: mcaStatus === 2 ? "MCA Partially Signed" : "",
      successMessage: mcaStatus === 1 ? "MCA Signed" : "",
      status: ckycStatus !== 1 ? -1 : mcaStatus,
    },
    {
      index: 3,
      title: "SIP eNach",
      warningMessage: "", // eNachStatusMessage
      successMessage: "",
      status: mcaStatus === 0 ? -1 : eNachStatus,
    },
  ];

  const handleProceed = async () => {
    if (ckycStatus && mcaStatus) {
      // history.push("/user/nach-e-mandates");
      contactTeam();
    } else if (ckycStatus) {
      history.push("/user/investmet-method");
    } else {
      history.push("/user/kyc");
    }
  };

  const fetchUserData = async () => {
    const userDetails = await getUserDetails();

    if (userDetails.status) {
      const { mcaStatus, ckycStatus, investorSip } = userDetails.data;
      if (investorSip) {
        if (
          investorSip?.enachStatus === process.env.REACT_APP_ENACH_STATUS_DRAFT
        ) {
          setENachStatus(0);
        } else if (
          investorSip?.enachStatus ===
          process.env.REACT_APP_ENACH_STATUS_INPROGRESS
        ) {
          setENachStatus(1);
        } else {
          setENachStatus(0);
          setENachStatusMessage(investorSip?.enachStatus);
        }
      }
      setMcaStatus(mcaStatus);
      setCkycStatus(ckycStatus);
    }
  };

  const getWalletData = async () => {
    const walletData = await getWallet();

    if (walletData.status) {
      const balanceAmt = walletData?.data?.balanceAmt ?? 0;
      setWalletAmount(balanceAmt);
    }
  };

  useEffect(() => {
    fetchUserData();
    getWalletData();
  }, []);

  const contactTeam = () => {
    setOpenSnackbar(true);
  };

  return (
    <CardLayout goHome>
      <div className="container mt-5">
        <h3 className="display-6 fw-bold text-center mb-5">My Profile</h3>

        <div className="row mb-5">
          <div className="col-12 col-md-6">
            <div className="p-5 mb-5 border rounded">
              <div className="d-flex align-items-center flex-column">
                <p className="mb-3">
                  Wallet Amount
                  <AddCircleIcon className="ms-2" onClick={() => history.push("/user/wallet-topup")} />
                </p>
                <h2 className="m-0 display-6">
                  INR {formattedoCurrencyNumbering(walletAmount)}
                </h2>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex align-items-center flex-column">
              {profileCompleteSteps.map((data, key) => {
                return (
                  <div
                    key={key}
                    className="profile-stepper-container w-100 px-md-5 mb-5"
                  >
                    <div className="me-3">
                      <div
                        className={
                          "profile-stepper-index " +
                          (data.status === 1
                            ? "bg-success text-white"
                            : "bg-light text-black")
                        }
                      >
                        {data.status === 1 ? <CheckRoundedIcon /> : data.index}
                      </div>
                      {profileCompleteSteps.length !== data.index ? (
                        <div
                          className={
                            "ps-strip mx-auto " +
                            (data.status === 1 ? "bg-success" : "bg-light")
                          }
                        ></div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="profile-stepper-content mt-3">
                      <h5
                        className={
                          "profile-stepper-title mb-3 " +
                          (data.status === -1 ? "text-light" : "")
                        }
                      >
                        {data.title}
                      </h5>
                      {/* {
                                                data.warningMessage !== "" ? <p className="profile-stepper-caption p-0 m-0">{data.successMessage}</p> : (data.status === 1 ? <p className="profile-stepper-caption p-0 m-0">{data.successMessage}</p> : <button className={'color-btn ' + (data.status === -1 ? 'd-none' : '')} onClick={handleProceed}>Proceed</button>)
                                            } */}
                      {
                        data.successMessage !== ""
                        ? <p className="profile-stepper-caption text-success p-0 m-0">
                          {data.successMessage}
                        </p>
                        : <></>
                      }
                      {
                        data.warningMessage !== ""
                        ? <p className="profile-stepper-caption text-warning p-0 m-0">
                          {data.warningMessage}
                        </p>
                        : <></>
                      }
                      {/* <button className={'color-btn ' + (data.status === -1 ? 'd-none' : '')} onClick={handleProceed}>Proceed</button> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-12">
          <InvestorDetails />
        </div>
      </div>

      <CustomSnackbar
        open={openSnackbar}
        severity="error"
        duration={4000}
        text={CONTACT_TEAM_MESSAGE}
        handleClose={handleSnackbarClose}
      />
    </CardLayout>
  );
}

export default MyProfile;
