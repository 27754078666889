import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserAuth, getPartner_Id, setPartner_Id } from '../../../utils/LocalStorageUtils';
import Footer from '../Footer';
import NavBar from '../NavBar';
import { fetchPartnerIdFromApi } from '../../../utils/FetchLogo';
import { useSnackbar } from '../../../context/SnackbarContext';

const AuthLayout = ({ title, children, partner_Id }) => {
  const history = useHistory();
  const [logoError, setLogoError] = useState(null);
  const auth = getUserAuth();
  const [logo, setLogo] = useState(null);
  const { showSnackbar } = useSnackbar()
  const [partnerId, setPartnerId] = useState(partner_Id || getPartner_Id());

  const fetchLogo = (partnerId) => {
    return `${process.env.REACT_APP_BASE_URL}/logo/${partnerId}`; 
  };

  useEffect(() => {
    if (!partnerId) {
      fetchPartnerIdFromApi(
        setPartnerId,
        setPartner_Id,
        showSnackbar
      );
      setLogo(fetchLogo(partnerId));
    } else {
      setLogo(fetchLogo(partnerId));
      setLogoError(null); 
    }
  }, [partnerId]);

  return (
    <div className='row mx-0 h-100vh auth-wrapper'>
      <div className="col-md">
        <img
          src={logo || '/default-logo.png'} 
          className="p-4"
          alt="Logo"
          onClick={() => history.push("/user/home")} 
        />
      </div>
      <div className="col-md-5 col-12 px-0 border-start border-end d-flex flex-column" style={{ minHeight: '100vh' }}>
        <div className="d-flex justify-content-center flex-column h-100 pt-5">
          <h2 className="display-6 mb-4 px-5 title">{title}</h2>
          <div className="px-5">
            {children}
          </div>
        </div>
        <div className="p-5">
          <Footer />
        </div>
      </div>
      <div className="col-md">
        {auth && <NavBar showOnlyMenu />}
      </div>
    </div>
  );
};

export default AuthLayout;

