import React, { useEffect, useState } from 'react';
import CardContainer from "../components/CardContainer";
import CustomReChart from './CustomReChart';

function Graph(props) {
    const { graphData, cardData } = props;

    const [chartData, setChartData] = useState([])

    useEffect(() => {

        const tempList = graphData.map(graphObj => {
            return {
                ...graphObj,
                cd: graphObj.committed + graphObj.deployed
            }
        })

        setChartData(tempList)

    }, [graphData])

    return (
        <div className="card my-4 p-4">
           {cardData && <CardContainer
               cardData={cardData}
            />}

            {chartData.length > 0 && <CustomReChart chartData={chartData} />}
        </div>
    );
}

export default Graph;