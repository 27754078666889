import React, { createContext, useContext, useState } from 'react'

const ButtonContext = createContext(undefined)

export function ButtonProvider({ children }) {
  const [buttonText, setButtonText] = useState('Explore Other Opportunities')

  return (
    <ButtonContext.Provider value={{ buttonText, setButtonText }}>
      {children}
    </ButtonContext.Provider>
  )
}

export function useButtonContext() {
  const context = useContext(ButtonContext)
  if (context === undefined) {
    throw new Error('useButtonContext must be used within a ButtonProvider')
  }
  return context
}
