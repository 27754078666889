import React, { useEffect, useState } from "react";
import "../../css/Opportunity/Card.css";
import OpportunityCard from "./OpportunityCard";
import { getAvailableProjects } from "../../apis/apis";
import CustomSnackbar from "../Toast/CustomSnackbar";
import ShimmerOpportunityContainer from "../Shimmer/ShimmerOpportunityContainer";
import { LSK_MCA_STATUS, getItem } from "../../../utils/LocalStorageUtils";
import { STATUS_CLOSED_OPPORTUNITY, STATUS_OPEN_OPPORTUNITY } from "../../../utils/constants/constants";


export default function CardContainer(props) {
  const [pageNumber, setPageNumber] = useState(1);

  const [recordCount, setRecordCount] = useState(4);
  const [ArrayOfData, setArreyOfData] = useState([]);
  const [errorData, setErrorData] = useState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mcaStatus, setMcaStatus] = useState(0)


  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const apiAvailableProjects = () => {
    setIsLoading(true);
    getAvailableProjects({
      oppStatus: props.opportunityType,
      pageNumber: pageNumber,
      recLimit: 4,
      isPrivate: 0
    })
      .then((data) => {
        if (pageNumber === 1) {
          setRecordCount(data.data.records);
        }
        setArreyOfData([...ArrayOfData, ...data.data.opportunityList]);
      })
      .catch((err) => {
        setErrorData(err);
        setOpenSnackbar(true);
      }).finally(e => {
        setTimeout(() => {
          setIsLoading()
        }, 400);
      });
  }

  const loadMoreBtn = () => {
    setPageNumber(pageNumber + 1);
  }

  useEffect(() => {
    apiAvailableProjects();
    setMcaStatus(+getItem(LSK_MCA_STATUS))
  }, [pageNumber]);

  return (
    <div className="mb-3">
      {
        recordCount > 0 && <p className="p-0 mb-3 text-uppercase">
          {props.title}
        </p>
      }
      {isLoading ? <ShimmerOpportunityContainer /> :
        recordCount > 0 && <>
          <div className="row">
            {ArrayOfData.map((data, index) => (
                  <div key={index} className="col-md-6 col-12 mb-4">
                    <OpportunityCard key={index} data={data} isHide={data.isInvested === 1 && props.opportunityType === STATUS_OPEN_OPPORTUNITY && mcaStatus === 1} />
                  </div>
            ))}
          </div>
          {
            ArrayOfData.length < recordCount ?
              <button className="btn btn-secondary py-2 px-3 mb-5" onClick={loadMoreBtn}>Load more</button> :
              <></>
          }
        </>
      }


      <CustomSnackbar
        open={openSnackbar}
        severity="error"
        duration={2000}
        text={errorData}
        handleClose={handleSnackbarClose}
      />
    </div >
  );
}
