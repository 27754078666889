import { useHistory } from "react-router-dom";
import { convertToCurrencyNumbering } from "../../../utils/userUtilities";
import { STATUS_OPEN_OPPORTUNITY } from "../../../utils/constants/constants";

export default function OpportunityCard({ data,isHide }) {

  const history = useHistory();

  const handleLearnMore = (id) => {
    history.push("/user/company-pdp/" + id);
  }

  return (
    <div className={`border rounded p-4 h-100 d-flex gap-4 align-items-center ${isHide?"bg-light":" pointer"} justify-content-between`} onClick={isHide?()=>{}:() => handleLearnMore(data.oppRid)}>
      <div className="d-flex align-items-center flex-grow-1"> 
        <img
          src={data.compLogo ? process.env.REACT_APP_BASE_URL + "/" + data.compLogo : process.env.PUBLIC_URL + "/img/placeholder.png"}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = process.env.PUBLIC_URL + "/img/placeholder.png";
          }}
          className="opportunity-card-img mb-2"
          alt="company-banner"
        />
        <p className="fs-large-medium ps-3">{data.oppTitle}</p>
      </div>
      
      <div className="d-flex gap-4 w-auto align-items-center flex-shrink-0" >
        <div>
          <p className="fs-5 mb-0 text-secondary">
            {convertToCurrencyNumbering(data.roundSize)}
          </p>
          <p className="mb-0">
            Raising
          </p>
        </div>
        <div>
          <p className="fs-5 mb-0 text-secondary">
            {convertToCurrencyNumbering(data.valuation)}
          </p>
          <p className="mb-0">
            Valuation
          </p>
        </div>
      </div>
    </div>
  );
}

OpportunityCard.defaultProps = {
  data: {
    bannerImgPath: "/img/company.png",
  },
};
