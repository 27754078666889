import UserLayout from "../userside/components/layout/UserLayout";
import Register from "../userside/pages/Register";
import OtpVerification from "../userside/pages/OtpVerification";
import SuccessfullPage from "../userside/pages/SuccessfullPage";
import KyCpage from "../userside/pages/KYCpage";
import KycConfirmation from "../userside/pages/KycConfirmation";
import AgreementPage from "../userside/pages/AgreementPage";
import McaSuccessfull from "../userside/pages/MCASuccessfull";
import NominationSuccessfull from "../userside/pages/nomination/NominationSuccessfull";
import FatcaSuccessfull from "../userside/pages/fatca/FatcaSuccessfull";
import HomePage from "../userside/pages/HomePage";
import LoadingPage from "../userside/pages/LoadingPage";
import ErrorPage from "../userside/pages/ErrorPage";
import Opportunites from "../userside/pages/Opportunities";
import InvestmentMethod from "../userside/pages/InvestmentMethod";
import CompanyPDP from "../userside/pages/CompanyPDP";
import LumpsumIO from "../userside/pages/LumpsumIO";
import InvestSIP from "../userside/pages/InvestSIP";
import NachEMandates from "../userside/pages/NachEMandates";
import CongratulationsSIP from "../userside/pages/CongratulationsSIP";
import LoginPage from "../userside/pages/LoginPage";
import MyProfile from "../userside/pages/MyProfile";
import InvestSIPMethod from "../userside/pages/InvestSIPMethod";
import PaymentPage from "../userside/pages/PaymentPage";
import PaymentGatewayPage from "../userside/pages/PaymentGatewayPage";
import WalletTopup from "../userside/pages/WalletTopup";
import DrawdownStatus from "../userside/pages/DrawdownStatus";
import ContactTeam from "../userside/pages/ContactTeam";
import AgreementLP from "../userside/pages/AgreementLP";
import SuccessLP from "../userside/pages/SuccessLP";
import ConsentSuccess from "../userside/pages/landing-page/ConsentSuccess";
import InvestmentDetail from "../userside/pages/InvestmentDetail";
import Preview from "../userside/pages/Preview";
import ShowInterest from "../userside/pages/ShowInterest";
import InvestNow from "../userside/pages/InvestNow";
import BankDetails from "../userside/pages/informtionGathering/BankDetails";
import DematDetails from "../userside/pages/informtionGathering/DematDetails";
import VerifyEmail from "../userside/pages/VerifyEmail";
import UpdateFullName from "../userside/pages/UpdateFullName";
import PaymentStatus from "../userside/pages/PaymentStatus";
import CkycNotAvailable from "../userside/pages/CkycNotAvailable";
import Transactions from "../userside/pages/Transactions";
import { Component } from "react";
import NominationPage from "../userside/pages/nomination/NominationPage";
import FatcaPage from "../userside/pages/fatca/FatcaPage";
import McaNominationDocuments from "../userside/pages/McaNominationDocuments";
import CommunicationDetails from "../userside/pages/informtionGathering/CommunicationDetails";

const Routes = [
  {
    path: "/",
    Component: LoginPage,
    Layout: UserLayout,
  },
  {
    path: "/register",
    Component: Register,
    Layout: UserLayout,
  },
  {
    path: "/otp",
    Component: OtpVerification,
    Layout: UserLayout,
  },
  {
    path: "/verify-email",
    Component: VerifyEmail,
    Layout: UserLayout,
  },
  {
    path: "/agreement-lp",
    Component: AgreementLP,
    Layout: UserLayout,
  },
  {
    path: "/success-lp",
    Component: SuccessLP,
    Layout: UserLayout,
  },
  {
    path: "/consent-success",
    Component: SuccessLP,
    Layout: ConsentSuccess,
  },
  {
    path: "/preview/:id",
    Component: Preview,
    Layout: UserLayout,
  },
  {
    path: "/show-interest/:id",
    Component: ShowInterest,
    Layout: UserLayout,
  },
  {
    path: "/user",
    Private: true,
    Component: () => <div>User Component Index</div>,
    Layout: UserLayout,
    modules: [
      {
        path: "/successfull",
        Component: SuccessfullPage,
        exact: true,
      },
      {
        path: "/kyc",
        Component: KyCpage,
        exact: true,
      },
      {
        path: "/update-name",
        Component: UpdateFullName,
        exact: true,
      },
      {
        path: "/ckyc-not-available",
        Component: CkycNotAvailable,
        exact: true,
      },
      // {
      //   path: "/kyc-confirmation",
      //   Component: KycConfirmation,
      //   exact: true,
      // },
      {
        path: "/investmet-method",
        Component: InvestmentMethod,
        exact: true,
      },
      {
        path: "/communication-details",
        Component: CommunicationDetails,
        exact: true,
      },
      {
        path: "/bank-details",
        Component: BankDetails,
        exact: true,
      },
      {
        path: "/demat-details",
        Component: DematDetails,
        exact: true,
      },
      {
        path: "/agreement",
        Component: AgreementPage,
        exact: true,
      },
      {
        path:"/nomination",
        Component:NominationPage,
        exact:true,
      },
      {
        path:"/fatca",
        Component:FatcaPage,
        exact:true,
      },
      // {
      //   path: "/agreement-info",
      //   Component: AgreementInfo,
      //   exact: true,
      // },
      {
        path: "/loading",
        Component: LoadingPage,
        exact: true,
      },
      {
        path: "/mca-status",
        Component: McaSuccessfull,
        exact: true,
      },
      {
        path:"/nomination-status",
        Component:NominationSuccessfull,
        exact:true,
      },
      {
        path:"/fatca-status",
        Component:FatcaSuccessfull,
        exact:true,
      },
      {
        path: "/home",
        Component: HomePage,
        exact: true,
      },
      {
        path: "/investment-detail/:ioRid",
        Component: InvestmentDetail,
        exact: true,
      },
      {
        path: "/invest-now/:oppRid",
        Component: InvestNow,
        exact: true,
      },
      {
        path: "/opportunites",
        Component: Opportunites,
        exact: true,
      },
      {
        path: "/company-pdp/:oppRid",
        Component: CompanyPDP,
        exact: true,
      },
      {
        path: "/lumpsum-io/:oppRid",
        Component: LumpsumIO,
        exact: true,
      },
      {
        path: "/invest-sip/:oppRid",
        Component: InvestSIP,
        exact: true,
      },
      {
        path: "/invest-sip-method",
        Component: InvestSIPMethod,
        exact: true,
      },
      {
        path: "/nach-e-mandates",
        Component: NachEMandates,
        exact: true,
      },
      {
        path: "/payment",
        Component: PaymentPage,
        exact: true,
      },
      {
        path: "/commitment-payment",
        Component: PaymentGatewayPage,
        exact: true,
      },
      {
        path: "/congratulations-sip",
        Component: CongratulationsSIP,
        exact: true,
      },
      {
        path: "/profile",
        Component: MyProfile,
        exact: true,
      },
      {
        path : "/documents",
        Component: McaNominationDocuments,
        exact:true,
      },
      {
        path: "/wallet-topup",
        Component: WalletTopup,
        exact: true,
      },
      {
        path: "/drawdown-status",
        Component: DrawdownStatus,
        exact: true,
      },
      {
        path: "/contact-team",
        Component: ContactTeam,
        exact: true,
      },
      {
        path: "/payment-status",
        Component: PaymentStatus,
        exact: true,
      },
      {
        path: "/transactions",
        Component: Transactions,
        exact: true,
      },
    ],
  },
  {
    path: "/error",
    Component: ErrorPage,
    exact: true,
  },
];

export default Routes;
