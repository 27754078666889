import React from "react";
import { Route, Redirect } from "react-router-dom";
import { COMPANY_PDP, getUserAuth, setItem } from "../utils/LocalStorageUtils";

const PrivateRoute = (props) => {
  let auth = getUserAuth();

  if(props.path.includes('/user/company-pdp/')){
    setItem(COMPANY_PDP, props.location.pathname?.substring(props.location.pathname?.lastIndexOf('/') + 1))
  }

  return (
    <Route
      path={props.path}
      render={({ location }) =>
        auth ? (
          <props.component />
        ) : (
          props.path === '/user/investmet-method' ||
          props.path === '/user/agreement' ||
          props.path === '/user/mca-status' ||
          props.path === '/user/drawdown' ||
          props.path === '/user/payment-scheme-emailer' ||
          props.path==='/user/nomination-status'||
          props.path==='/user/nomination'||
          props.path==='/user/fatca-status'||
          props.path=== '/user/fatca'||
          props.path.includes('payment-status') ||
          props.path.includes('commitment-payment')  ||
          props.path.includes('invest-now') ||
          props.path.includes('otp')      
          ? <props.component /> :
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        )
      }
    />
  );
};
export default PrivateRoute;
